import { computed, ref } from '@vue/composition-api';

import {
  BaseSelectSearchItem,
  BaseSelectSearchSearchText,
  BaseSelectSearchValue,
} from '@/base/app/components/atoms/BaseSelectSearchComposable';
import { or } from '@/base/app/utils/FilterUtils';
import { UserTagReference } from '@/base/domains';
import { Optional } from '@/base/types';

export type UserTagSelectValue = Optional<BaseSelectSearchValue>;

export type UserTagSelectItem = BaseSelectSearchItem<UserTagReference>;

export type PropsUserTagSelect = {
  value: UserTagSelectValue;
  tags: UserTagSelectItem[];
};

export function useUserTagSelect(
  props: PropsUserTagSelect,
  emit: (name: string, arg: UserTagSelectValue) => void
) {
  const text = ref<BaseSelectSearchSearchText>();
  const filtered = computed(() =>
    props.tags
      .filter((item) => or(item.text, text.value))
      .sort((a, b) => {
        if (a.text === b.text) return a.id < b.id ? -1 : 1;
        return a.text < b.text ? -1 : 1;
      })
  );

  function findTag(id: string) {
    return props.tags.find((item) => item.id === id);
  }

  function search(v: BaseSelectSearchSearchText) {
    text.value = v;
  }

  function change(v: BaseSelectSearchValue) {
    emit('change', v);
  }

  return {
    text,
    filtered,
    findTag,
    search,
    change,
  };
}
