












import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';

import { useSignUpIndexCode } from './SignUpIndexCodeComposable';

export default defineComponent({
  name: 'AccountSignUpIndexCode',
  components: { BaseMarkdown, BaseButton },
  props: { tenantCode: { type: String, required: true } },
  emits: ['move'],
  setup(_, { emit }: SetupContext) {
    return useSignUpIndexCode(emit);
  },
});
