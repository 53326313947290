












import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';

import { SignUpTenantValue, useSignUpTenant } from './SignUpTenantComposable';
import TenantForm from './TenantForm.vue';

export default defineComponent({
  name: 'AccountSignUpTenant',
  components: { BaseMarkdown, BaseButton, TenantForm },
  inheritAttrs: false,
  model: { prop: 'value', event: 'change' },
  props: { value: { type: Object as PropType<SignUpTenantValue>, required: true } },
  emits: ['change', 'move'],
  setup(_, { emit }: SetupContext) {
    return useSignUpTenant(emit);
  },
});
