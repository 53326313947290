import { onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';

export type TenantFormValue = {
  name: string;
  code: string;
  mode: 'email' | 'code';
};

export type PropsTenantForm = {
  value: TenantFormValue;
};

export function useTenantForm(
  props: PropsTenantForm,
  emit: (name: string, arg: TenantFormValue) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<TenantFormValue>({ name: '', code: '', mode: 'email' });

  function change(payload: object) {
    Object.assign(input.value, payload);
  }
  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    emit('submit', { ...input.value });
  }

  function init() {
    input.value = { ...props.value };
  }
  onMounted(init);
  watch(() => props.value, init);

  const msgs = useMessages({ prefix: 'account.molecules.tenantForm' });
  return {
    observer,
    input,
    labelTenantName: msgs.of('tenantName'),
    labelTenantCode: msgs.of('tenantCode'),
    labelSignUpMode: msgs.of('signUpMode'),
    hint: msgs.of('hintOfTenantCode'),
    options: msgs.listOf('signUpModes'),
    change,
    submit,
  };
}
