import { onMounted, ref } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';

export type SignInCodeFormValue = {
  tenantCode: string;
  code: string;
  password: string;
};

export type PropsSignInCodeForm = {
  tenantCode?: string;
  code?: string;
};

export function useSignInCodeForm(
  props: PropsSignInCodeForm,
  emit: (name: string, arg: SignInCodeFormValue) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<SignInCodeFormValue>({ tenantCode: '', code: '', password: '' });

  const trimSpaces = (v: string) => v.replaceAll(/^\s+|\s+$/g, '');
  const onTenantCodeChange = (v: string) => {
    input.value = {
      ...input.value,
      tenantCode: trimSpaces(v),
    };
  };
  const onCodeChange = (v: string) => {
    input.value = {
      ...input.value,
      code: trimSpaces(v),
    };
  };
  const onPasswordChange = (v: string) => {
    input.value = {
      ...input.value,
      password: trimSpaces(v),
    };
  };

  const display = ref(false);

  function toggleDisplay() {
    display.value = !display.value;
  }

  function init() {
    input.value.tenantCode = trimSpaces(props.tenantCode ?? '');
    input.value.code = trimSpaces(props.code ?? '');
  }
  onMounted(init);

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    emit('submit', { ...input.value });
  }
  const msgs = useMessages({ prefix: 'account.molecules.signInCodeForm' });
  return {
    observer,
    input,
    display,
    placeholderTenant: msgs.of('placeholderTenant'),
    labelTenant: msgs.of('labelTenant'),
    placeholderUserCode: msgs.of('placeholderUserCode'),
    labelUserCode: msgs.of('labelUserCode'),
    placeholderPassword: msgs.of('placeholderPassword'),
    labelPassword: msgs.of('labelPassword'),
    submit,
    toggleDisplay,
    onTenantCodeChange,
    onCodeChange,
    onPasswordChange,
  };
}
