import { useMessages } from '@/base/app';

export type SignUpIndexCodeMovePayload = {
  to: number;
};

export function useSignUpIndexCode(emit: (name: string, arg: SignUpIndexCodeMovePayload) => void) {
  function next() {
    emit('move', { to: 1 });
  }
  const msgs = useMessages({ prefix: 'account.molecules.signUpIndexCode' });
  return {
    description: msgs.of('description'),
    labelTenantCode: msgs.of('tenantCode'),
    labelNext: msgs.of('next'),
    next,
  };
}
