import { onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import { assertIsDefined } from '@/utils/Asserts';

export type UserCreateTypeFormValue = {
  setInitialPassword: boolean;
};

export type PropsUserCreateTypeForm = {
  value: UserCreateTypeFormValue;
};

export function useUserCreateTypeForm(
  props: PropsUserCreateTypeForm,
  emit: (name: string, arg: UserCreateTypeFormValue) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<'true' | 'false'>();

  function init() {
    input.value = props.value.setInitialPassword ? 'true' : 'false';
  }
  onMounted(init);
  watch(() => props.value, init);

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    assertIsDefined(input.value, 'input');
    emit('submit', { setInitialPassword: input.value === 'true' });
  }

  const msgs = useMessages({ prefix: 'account.molecules.userCreateTypeForm' });
  return {
    observer,
    input,
    labelInitialPassword: msgs.of('initialPassword'),
    initialPasswordValues: msgs.listOf('initialPasswordValues'),
    submit,
  };
}

export type UserCreateTypeForm = ReturnType<typeof useUserCreateTypeForm>;
