







import { defineComponent } from '@vue/composition-api';

import MyApp from '@/base/app/components/MyApp.vue';

import RequestResetPassword from '../components/organisms/RequestResetPassword.vue';

export default defineComponent({
  name: 'AccountRequestResetPasswordPage',
  components: { MyApp, RequestResetPassword },
  props: { email: { type: String, default: undefined } },
});
