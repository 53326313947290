import { onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import { useColor } from '@/base/app/utils/ColorUtils';

export type UserTagFormValue = {
  text: string;
  color: string;
};

export type PropsUserTagForm = {
  value: UserTagFormValue;
};

export function useUserTagForm(
  props: PropsUserTagForm,
  emit: (name: string, arg: UserTagFormValue) => void
) {
  const { colors } = useColor();

  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<UserTagFormValue>({ text: '', color: '' });

  function change(payload: object) {
    Object.assign(input.value, payload);
  }
  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    emit('submit', { ...input.value });
  }

  function init() {
    input.value = { ...props.value };
    const [first] = colors.value;
    if (!input.value.color && first) input.value.color = first.value;
  }
  onMounted(init);
  watch(() => props.value, init);

  function reset() {
    if (observer.value) observer.value.reset();
  }
  const msgs = useMessages({ prefix: 'account.molecules.userTagForm' });
  return { observer, input, labelText: msgs.of('text'), change, submit, reset, colors };
}

export type UserTagForm = ReturnType<typeof useUserTagForm>;
