import { onMounted, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { isFailed } from '@/base/usecases';
import { useRouter } from '@/utils/VueUtils';

import { useForgotPassword } from '../../../usecases';
import { EmailFormValue } from '../molecules/EmailFormComposable';

export type PropsRequestResetPassword = {
  email?: string;
};

export function useRequestResetPassword(props: PropsRequestResetPassword) {
  const router = useRouter();
  const forgotPassword = useForgotPassword();

  const input = ref<EmailFormValue>('');
  const loading = ref(false);
  const errors = ref<ErrorMessage[]>();

  function init() {
    input.value = props.email || '';
  }
  onMounted(init);

  async function submit() {
    errors.value = undefined;
    loading.value = true;
    const res = await forgotPassword.execute({ email: input.value });
    if (isFailed(res)) {
      errors.value = res.errors;
      loading.value = false;
      return;
    }
    waitTransition(() => {
      loading.value = false;
      router.push({ name: 'resetPassword', query: { email: input.value } });
    });
  }

  const msgs = useMessages({ prefix: 'account.organisms.requestResetPassword' });
  return {
    input,
    loading,
    errors,
    labelSend: msgs.of('send'),
    description: msgs.of('description'),
    submit,
  };
}
