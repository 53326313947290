import { onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';

export type CodePasswordFormValue = {
  code: string;
  password: string;
};

export type PropsCodePasswordForm = {
  value: CodePasswordFormValue;
};

export function useCodePasswordForm(
  props: PropsCodePasswordForm,
  emit: (name: string, arg: CodePasswordFormValue) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<CodePasswordFormValue>({ code: '', password: '' });
  const confirmation = ref();
  const display = ref(false);
  const displayConfirm = ref(false);

  function toggleDisplay() {
    display.value = !display.value;
  }

  function toggleDisplayConfirm() {
    displayConfirm.value = !displayConfirm.value;
  }

  function change(payload: object) {
    Object.assign(input.value, payload);
  }
  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    emit('submit', { ...input.value });
  }

  function init() {
    input.value = { ...props.value };
    if (!input.value.password) confirmation.value = undefined;
  }
  onMounted(init);
  watch(() => props.value, init);

  const msgs = useMessages({ prefix: 'account.molecules.codePasswordForm' });
  return {
    observer,
    input,
    confirmation,
    display,
    displayConfirm,
    labelCode: msgs.of('code'),
    labelNewPassword: msgs.of('newPassword'),
    labelConfirmPassword: msgs.of('confirmPassword'),
    pwdTitle: msgs.of('aboutPassword'),
    pwdDescription: msgs.of('description'),
    toggleDisplay,
    change,
    submit,
    toggleDisplayConfirm,
  };
}
