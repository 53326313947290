







































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseText from '@/base/app/components/atoms/BaseText.vue';

import { PropsSignInCodeForm, useSignInCodeForm } from './SignInCodeFormComposable';

type Props = PropsSignInCodeForm;

export default defineComponent({
  name: 'AccountSignInCodeForm',
  components: { BaseText },
  props: {
    tenantCode: { type: String, default: undefined },
    code: { type: String, default: undefined },
  },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useSignInCodeForm(props, emit);
  },
});
