import { ref } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';

import { useMessages } from '@/base/app';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { isFailed } from '@/base/usecases';
import { useRouter } from '@/utils/VueUtils';

import { useChangePassword, useForgotPassword } from '../../../usecases';
import { CodePasswordFormValue } from '../molecules/CodePasswordFormComposable';

export type PropsResetPassword = {
  email: string;
};

export function useResetPassword(props: PropsResetPassword) {
  const msgs = useMessages({ prefix: 'account.organisms.resetPassword' });
  const router = useRouter();

  const input = ref<CodePasswordFormValue>({ code: '', password: '' });
  const loading = ref(false);
  const errors = ref<ErrorMessage[]>();
  const infos = ref<string[]>();

  const changePassword = useChangePassword();
  async function submit() {
    errors.value = undefined;
    infos.value = undefined;
    loading.value = true;
    const res = await changePassword.execute({ ...input.value, email: props.email });
    if (isFailed(res)) {
      loading.value = false;
      errors.value = res.errors;
      return;
    }
    waitTransition(() => {
      loading.value = false;
      router.replace({ name: 'signIn', query: { email: props.email } });
    });
  }

  const forgotPassword = useForgotPassword();
  async function resend() {
    errors.value = undefined;
    infos.value = undefined;
    loading.value = true;
    const res = await forgotPassword.execute({ email: props.email });
    if (isFailed(res)) {
      loading.value = false;
      errors.value = res.errors;
      return;
    }
    waitTransition(() => {
      loading.value = false;
      infos.value = [msgs.of('sent').value];
      useTimeoutFn(() => {
        infos.value = undefined;
      }, 3000);
    });
  }
  return {
    input,
    loading,
    errors,
    infos,
    labelSet: msgs.of('set'),
    labelResendCode: msgs.of('resendCode'),
    description: msgs.of('description'),
    submit,
    resend,
  };
}
