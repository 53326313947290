


























































import { defineComponent } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import LogoButton from '@/base/app/components/atoms/LogoButton.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';

import SignUpIndexCode from '../molecules/SignUpIndexCode.vue';
import SignUpPassword from '../molecules/SignUpPassword.vue';
import SignUpUser from '../molecules/SignUpUser.vue';
import { PropsSignUpCode, useSignUpCode } from './SignUpCodeComposable';

type Props = PropsSignUpCode;

export default defineComponent({
  name: 'AccountSignUpCode',
  components: {
    LogoButton,
    SignUpIndexCode,
    SignUpUser,
    SignUpPassword,
    BaseButton,
    BaseMarkdown,
    ErrorMessages,
  },
  props: {
    tenantCode: { type: String, required: true },
    code: { type: String, required: true },
  },
  setup(props: Props) {
    return useSignUpCode(props);
  },
});
