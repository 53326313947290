






























import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseText from '@/base/app/components/atoms/BaseText.vue';

import { PropsSignInEmailForm, useSignInEmailForm } from './SignInEmailFormComposable';

type Props = PropsSignInEmailForm;

export default defineComponent({
  name: 'AccountSignInEmailForm',
  components: { BaseText },
  props: { email: { type: String, default: undefined } },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useSignInEmailForm(props, emit);
  },
});
