
































import { defineComponent, SetupContext } from '@vue/composition-api';

import RefreshButton from '@/base/app/components/atoms/RefreshButton.vue';
import BaseDialogConfirm from '@/base/app/components/molecules/BaseDialogConfirm.vue';
import BaseDialogFullScreen from '@/base/app/components/molecules/BaseDialogFullScreen.vue';

import UserTagDialog from '../molecules/UserTagDialog.vue';
import UserTagTable from '../molecules/UserTagTable.vue';
import { useUserTagsDialog } from './UserTagsDialogComposable';

export default defineComponent({
  name: 'AccountUserTagsDialog',
  components: {
    BaseDialogFullScreen,
    UserTagDialog,
    UserTagTable,
    BaseDialogConfirm,
    RefreshButton,
  },
  emits: ['done'],
  setup(_, { emit }: SetupContext) {
    return useUserTagsDialog(emit);
  },
});
