
















import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';

import { useSignUpSendCode } from './SignUpSendCodeComposable';

export default defineComponent({
  name: 'AccountSignUpSendCode',
  components: { BaseMarkdown, BaseButton, ErrorMessages },
  inheritAttrs: false,
  props: {
    email: { type: String, required: true },
    loading: { type: Boolean, default: false },
    errors: { type: Array as PropType<ErrorMessage[]>, default: undefined },
  },
  emits: ['move', 'submit'],
  setup(_, { emit }: SetupContext) {
    return useSignUpSendCode(emit);
  },
});
