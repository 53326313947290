







import { defineComponent } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import MyApp from '@/base/app/components/MyApp.vue';
import NotFound from '@/base/app/components/organisms/NotFound.vue';

export default defineComponent({
  name: 'AccountSignedOutPage',
  components: { MyApp, NotFound },
  setup() {
    const msgs = useMessages({ prefix: 'account.pages.signedOutPage' });
    return { header: msgs.of('signedOut'), body: msgs.of('message') };
  },
});
