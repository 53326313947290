





















import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';

import { useUserTagDialog } from './UserTagDialogComposable';
import UserTagForm from './UserTagForm.vue';

export default defineComponent({
  name: 'AccountUserTagDialog',
  components: { BaseButton, UserTagForm, ErrorMessages },
  inheritAttrs: false,
  emits: ['submit'],
  setup(_, { emit }: SetupContext) {
    return useUserTagDialog(emit);
  },
});
