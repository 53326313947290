





















































import { defineComponent } from '@vue/composition-api';

import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import LogoButton from '@/base/app/components/atoms/LogoButton.vue';

import SignUpIndexEmail from '../molecules/SignUpIndexEmail.vue';
import SignUpPassword from '../molecules/SignUpPassword.vue';
import SignUpSendCode from '../molecules/SignUpSendCode.vue';
import SignUpUser from '../molecules/SignUpUser.vue';
import { PropsSignUpEmail, useSignUpEmail } from './SignUpEmailComposable';

type Props = PropsSignUpEmail;

export default defineComponent({
  name: 'AccountSignUpEmail',
  components: {
    LogoButton,
    SignUpIndexEmail,
    SignUpUser,
    SignUpPassword,
    SignUpSendCode,
    BaseMarkdown,
  },
  props: {
    tenantCode: { type: String, required: true },
    email: { type: String, required: true },
  },
  setup(props: Props) {
    return useSignUpEmail(props);
  },
});
