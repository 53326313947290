import { computed, onMounted, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { TermsOfService } from '@/base/domains';
import { isSucceeded, useGetLatestTermsOfService } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { useRouter } from '@/utils/VueUtils';

import { useCreateSupervisor } from '../../../usecases';
import { SignUpIndexMovePayload } from '../molecules/SignUpIndexComposable';
import {
  SignUpPasswordMovePayload,
  SignUpPasswordValue,
} from '../molecules/SignUpPasswordComposable';
import { SignUpSendCodeMovePayload } from '../molecules/SignUpSendCodeComposable';
import { SignUpTenantMovePayload, SignUpTenantValue } from '../molecules/SignUpTenantComposable';
import { SignUpTermsOfServiceMovePayload } from '../molecules/SignUpTermsOfServiceComposable';
import { SignUpUserMovePayload, SignUpUserValue } from '../molecules/SignUpUserComposable';

const PAGES = ['index', 'tenant', 'user', 'password', 'send-code'];
const PAGES_TERMS_OF_SERVICE = [
  'index',
  'terms-of-service',
  'tenant',
  'user',
  'password',
  'send-code',
];
const MIN_HEIGHT = 'min(460px, calc(100vh - 140px))';

type Form = {
  tenant: SignUpTenantValue;
  user: SignUpUserValue;
  password: SignUpPasswordValue;
};

export function useSignUp() {
  const msgs = useMessages({ prefix: 'account.organisms.signUp' });
  const router = useRouter();

  const loading = ref(false);
  const termsOfService = ref<TermsOfService>();
  const getTermsOfService = useGetLatestTermsOfService();
  async function fetch() {
    loading.value = true;
    const res = await getTermsOfService.execute({});
    if (isSucceeded(res)) termsOfService.value = res.termsOfService;
    else termsOfService.value = undefined;
    loading.value = false;
  }
  onMounted(fetch);

  const page = ref<string>();
  const input = ref<Form>({
    tenant: { name: '', code: '', mode: 'email' },
    user: { name: '', email: '', code: '' },
    password: { password: '' },
  });
  const errors = ref<ErrorMessage[]>();
  const pages = computed(() => (termsOfService.value ? PAGES_TERMS_OF_SERVICE : PAGES));

  function movePage(
    payload:
      | SignUpIndexMovePayload
      | SignUpTermsOfServiceMovePayload
      | SignUpTenantMovePayload
      | SignUpUserMovePayload
      | SignUpPasswordMovePayload
      | SignUpSendCodeMovePayload,
    clearErrors = false
  ) {
    if (!page.value) {
      [page.value] = pages.value;
      return;
    }
    const i = pages.value.indexOf(page.value) + payload.to;
    if (i < 0 || i >= pages.value.length) return;
    page.value = pages.value[i];
    if (clearErrors) errors.value = undefined;
  }

  const createSupervisor = useCreateSupervisor();
  async function submit() {
    errors.value = undefined;
    loading.value = true;
    assertIsDefined(termsOfService.value, 'termsOfService');
    try {
      const res = await createSupervisor.execute({
        tenantCode: input.value.tenant.code,
        tenantName: input.value.tenant.name,
        userName: input.value.user.name,
        userCode: input.value.tenant.mode === 'code' ? input.value.user.code : undefined,
        email: input.value.user.email,
        password: input.value.password.password,
        termsOfServiceId: termsOfService.value.id,
      });
      if (isSucceeded(res)) {
        router.push({ name: 'activateAccount', query: { id: res.userId } });
      } else {
        errors.value = res.errors;
      }
    } catch (e) {
      errors.value = [msgs.of('failed').value];
    }
    loading.value = false;
  }

  const progress = computed(() => {
    if (!page.value) return 0;
    const i = pages.value.indexOf(page.value);
    return ((i + 1) / pages.value.length) * 100;
  });
  return {
    loading,
    termsOfService,
    page,
    input,
    errors,
    progress,
    noTermsOfService: msgs.of('noTermsOfService'),
    minHeight: MIN_HEIGHT,
    movePage,
    submit,
  };
}
