import { onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import { Role } from '@/base/domains';

export type UserRoleFormValue = {
  role: Role;
  code?: string;
  email?: string;
  name?: string;
};

export type PropsUserRoleFrom = {
  value: UserRoleFormValue;
};

export function useUserRoleForm(
  props: PropsUserRoleFrom,
  emit: (name: string, arg: UserRoleFormValue) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<UserRoleFormValue>({ role: 'general' });

  function reset() {
    if (observer.value) observer.value.reset();
  }

  function change(payload: object) {
    Object.assign(input.value, payload);
  }

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    emit('submit', { ...input.value });
  }

  function init() {
    input.value = { ...props.value };
  }
  onMounted(init);
  watch(() => props.value, init);

  const msgs = useMessages({ prefix: 'account.molecules.userRoleForm' });
  return {
    observer,
    input,
    labelRole: msgs.of('role'),
    roles: msgs.listOf('roles'),
    labelName: msgs.of('name'),
    labelMailAddress: msgs.of('mailAddress'),
    labelUserCode: msgs.of('userCode'),
    hint: msgs.of('hintOfUserCode'),
    reset,
    change,
    submit,
  };
}

export type UserRoleForm = ReturnType<typeof useUserRoleForm>;
