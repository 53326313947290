
















import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';

import PasswordForm from './PasswordForm.vue';
import { SignUpPasswordValue, useSignUpPassword } from './SignUpPasswordComposable';

export default defineComponent({
  name: 'AccountSignUpPassword',
  components: { BaseMarkdown, BaseButton, PasswordForm },
  inheritAttrs: false,
  model: { prop: 'value', event: 'change' },
  props: { value: { type: Object as PropType<SignUpPasswordValue>, required: true } },
  emits: ['change', 'move'],
  setup(_, { emit }: SetupContext) {
    return useSignUpPassword(emit);
  },
});
