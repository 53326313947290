





























import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';

import PasswordForm from '../molecules/PasswordForm.vue';
import { PropsPasswordDialog, usePasswordDialog } from './PasswordDialogComposable';

type Props = PropsPasswordDialog;

export default defineComponent({
  name: 'AccountPasswordDialog',
  components: { BaseMarkdown, BaseButton, PasswordForm, ErrorMessages },
  inheritAttrs: false,
  props: {
    label: { type: String, required: true },
    showForcePasswordChange: { type: Boolean, default: false },
    height: { type: String, default: '320px' },
  },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return usePasswordDialog(props, emit);
  },
});
