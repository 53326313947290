import { computed, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { Optional } from '@/base/types';

type Target =
  | {
      code: string;
      name: string;
      groupsBelongsTo: Optional<string[]>;
      tenantCode: string;
    }
  | {
      email: string;
      groupsBelongsTo: Optional<string[]>;
      name: string;
    };

type detailRow = {
  key: string;
  value: string;
};

const PAGES_CODE = ['userDetails', 'signInPage'];
const PAGES_EMAIL = ['userDetails'];

export function useInvitedUserDetailsDialog() {
  const msgs = useMessages({ prefix: 'account.molecules.invitedUserDetailsDialog' });

  const dialog = ref(false);
  const target = ref<Target>();
  const page = ref<number>(0);

  const pages = computed(() => {
    if (!target.value) return [];
    return 'email' in target.value
      ? PAGES_EMAIL.map((p, i) => ({ index: i, label: msgs.of(p).value }))
      : PAGES_CODE.map((p, i) => ({ index: i, label: msgs.of(p).value }));
  });

  function close() {
    target.value = undefined;
    dialog.value = false;
    page.value = 0;
  }

  function open(payload: Target) {
    target.value = payload;
    dialog.value = true;
  }

  const details = computed(() => {
    const ret: detailRow[] = [];
    if (!target.value) return [];
    const groups = target.value.groupsBelongsTo?.join(',') ?? msgs.of('noGroups').value;
    const name = target.value.name || msgs.of('noName').value;
    if ('code' in target.value) {
      ret.push(
        { key: 'code', value: target.value.code },
        { key: 'name', value: name },
        { key: 'groupsBelongsTo', value: groups }
      );
      return ret.map((item) => ({ ...item, title: msgs.of(item.key).value }));
    }
    if ('email' in target.value) {
      ret.push(
        { key: 'email', value: target.value.email },
        { key: 'name', value: name },
        { key: 'groupsBelongsTo', value: groups }
      );
      return ret.map((item) => ({ ...item, title: msgs.of(item.key).value }));
    }
    return [];
  });

  const info = computed(() => {
    if (!target.value || 'email' in target.value) return '';
    const { tenantCode, code } = target.value;
    const url = new URL('/signup', document.location.origin);
    url.searchParams.set('tenantCode', tenantCode);
    url.searchParams.set('code', code);
    return msgs.of('descriptionSignUpCode', { code, path: url.toString() }).value;
  });

  return {
    dialog,
    details,
    page,
    pages,
    target,
    info,
    labelMessage: msgs.of('message'),
    labelClose: msgs.of('close'),
    close,
    open,
  };
}

export type InvitedUserDetailsDialog = ReturnType<typeof useInvitedUserDetailsDialog>;
