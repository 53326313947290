import { computed, ref } from '@vue/composition-api';

import { UserTagReference } from '@/base/domains';
import { isSucceeded, useGetUserTags } from '@/base/usecases';
import { injectionKeyOf, readonly } from '@/utils/VueUtils';

import {
  GetUsersIncludingSignUpReservationReservation,
  GetUsersIncludingSignUpReservationUser,
  useGetUsersIncludingSignUpReservation,
} from '../../usecases';

type User = GetUsersIncludingSignUpReservationUser | GetUsersIncludingSignUpReservationReservation;

export function useUsersStore() {
  const users = ref<User[]>([]);
  const userTags = ref<UserTagReference[]>([]);
  const loading = ref(false);

  const getUsers = useGetUsersIncludingSignUpReservation();
  async function fetchUsers() {
    users.value = [];
    const res = await getUsers.execute({});
    if (isSucceeded(res)) return res.users;
    return [];
  }

  const getUserTags = useGetUserTags();
  async function fetchUserTags() {
    userTags.value = [];
    const res = await getUserTags.execute({});
    if (isSucceeded(res)) return res.userTags;
    return [];
  }

  async function fetch() {
    loading.value = true;
    users.value = await fetchUsers();
    userTags.value = await fetchUserTags();
    loading.value = false;
  }

  const number = computed(() => {
    return users.value.filter((u) => u.status !== 'disabled').length;
  });
  return {
    users: readonly(users),
    userTags: readonly(userTags),
    number: readonly(number),
    loading,
    fetch,
  };
}

export type UsersStore = ReturnType<typeof useUsersStore>;

export const UsersStoreKey = injectionKeyOf<UsersStore>({
  boundedContext: 'account',
  type: 'store',
  name: 'UsersStore',
});
