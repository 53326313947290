


































import { defineComponent } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import LogoButton from '@/base/app/components/atoms/LogoButton.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';

import CodeForm from '../molecules/CodeForm.vue';
import { PropsActivateAccount, useActivateAccount } from './ActivateAccountComposable';

type Props = PropsActivateAccount;

export default defineComponent({
  name: 'AccountActivateAccount',
  components: { BaseMarkdown, BaseButton, LogoButton, CodeForm, ErrorMessages },
  props: { id: { type: String, required: true } },
  setup(props: Props) {
    return useActivateAccount(props);
  },
});
