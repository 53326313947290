


























import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';

import UserForm from '../molecules/UserForm.vue';
import { useUserNameDialog } from './UserNameDialogComposable';

export default defineComponent({
  name: 'AccountUserNameDialog',
  components: { UserForm, ErrorMessages, BaseButton },
  emits: ['done'],
  setup(_, { emit }: SetupContext) {
    return useUserNameDialog(emit);
  },
});
