





















































import { defineComponent } from '@vue/composition-api';

import LogoButton from '@/base/app/components/atoms/LogoButton.vue';

import SignUpIndex from '../molecules/SignUpIndex.vue';
import SignUpPassword from '../molecules/SignUpPassword.vue';
import SignUpSendCode from '../molecules/SignUpSendCode.vue';
import SignUpTenant from '../molecules/SignUpTenant.vue';
import SignUpTermsOfService from '../molecules/SignUpTermsOfService.vue';
import SignUpUser from '../molecules/SignUpUser.vue';
import { useSignUp } from './SignUpComposable';

export default defineComponent({
  name: 'AccountSignUpSupervisor',
  components: {
    LogoButton,
    SignUpTermsOfService,
    SignUpIndex,
    SignUpUser,
    SignUpTenant,
    SignUpPassword,
    SignUpSendCode,
  },
  setup() {
    return useSignUp();
  },
});
