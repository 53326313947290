import { computed, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { UserTagId, UserTagReference } from '@/base/domains';
import { ApplicationError } from '@/base/error';
import { USER_TAGS_DO_NOT_EXIST } from '@/base/ErrorCodes';
import { Optional } from '@/base/types';
import { isFailed, useSetTagsToUsers } from '@/base/usecases';

type UserTagItem = UserTagReference & {
  checked: boolean;
};

export type PropsUserTagSetDialog = {
  tags: UserTagReference[];
};

export type UserTagSetDialogChangePayload = Optional<UserTagId[]>;

export function useUserTagSetDialog(props: PropsUserTagSetDialog, emit: (name: string) => void) {
  const dialog = ref(false);
  const loading = ref(false);
  const userIds = ref<string[]>([]);
  const tagIds = ref<string[]>([]);
  const errors = ref<ErrorMessage[]>();

  function close() {
    dialog.value = false;
    errors.value = undefined;
  }

  function open(payload: { userIds: string[]; tagIds: string[] }) {
    userIds.value = [...payload.userIds];
    tagIds.value = [...payload.tagIds];
    dialog.value = true;
  }

  function change(item: UserTagItem) {
    const i = tagIds.value.indexOf(item.id);
    if (i === -1) {
      tagIds.value.push(item.id);
    } else {
      tagIds.value.splice(i, 1);
    }
  }

  const setTags = useSetTagsToUsers();
  async function submit() {
    errors.value = undefined;
    loading.value = true;
    const res = await setTags.execute({
      userIds: userIds.value,
      tagIds: tagIds.value,
    });
    if (isFailed(res)) {
      loading.value = false;
      errors.value = res.errors;
      return;
    }
    loading.value = false;
    emit('done');
    close();
  }

  const items = computed(() =>
    props.tags
      .map((t) => ({
        ...t,
        checked: tagIds.value.includes(t.id),
      }))
      .sort((a, b) => {
        if (a.text === b.text) return a.id < b.id ? -1 : 1;
        return a.text < b.text ? -1 : 1;
      })
  );

  function toMessageParams(aes: ApplicationError[]) {
    const tags = aes
      .map((e) => {
        if (!e.payload) return [];
        const p = e.payload as Parameters<typeof USER_TAGS_DO_NOT_EXIST.toApplicationError>[0];
        return p?.tagIds.map((id) => props.tags.find((t) => t.id === id)?.text || id) ?? [];
      })
      .flat();
    return { tags: tags.join() };
  }
  const replaceErrors = [
    {
      from: 'BS0016',
      to: {
        prefix: 'account.organisms.userTagSetDialog',
        key: 'failedSetTagTagsNotExists',
        values: toMessageParams,
      },
    },
  ];

  const msgs = useMessages({ prefix: 'account.organisms.userTagSetDialog' });
  return {
    dialog,
    loading,
    errors,
    items,
    replaceErrors,
    labelClose: msgs.of('close'),
    labelSet: msgs.of('set'),
    labelNoData: msgs.of('noData'),
    close,
    open,
    change,
    submit,
  };
}

export type UserTagSetDialog = ReturnType<typeof useUserTagSetDialog>;
