















import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseRadioGroup from '@/base/app/components/atoms/BaseRadioGroup.vue';

import {
  PropsUserCreateTypeForm,
  UserCreateTypeFormValue,
  useUserCreateTypeForm,
} from './UserCreateTypeFormComposable';

type Props = PropsUserCreateTypeForm;

export default defineComponent({
  name: 'AccountUserCreateTypeForm',
  components: { BaseRadioGroup },
  model: { prop: 'value', event: 'submit' },
  props: {
    value: { type: Object as PropType<UserCreateTypeFormValue>, required: true },
  },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useUserCreateTypeForm(props, emit);
  },
});
