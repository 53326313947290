





















import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseSelectSearch from '@/base/app/components/atoms/BaseSelectSearch.vue';
import UserTag from '@/base/app/components/atoms/UserTag.vue';

import {
  PropsUserTagSelect,
  UserTagSelectItem,
  UserTagSelectValue,
  useUserTagSelect,
} from './UserTagSelectComposable';

type Props = PropsUserTagSelect;

export default defineComponent({
  name: 'AccountUserTagSelect',
  components: { BaseSelectSearch, UserTag },
  inheritAttrs: false,
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: [String, Array] as PropType<UserTagSelectValue>, default: undefined },
    tags: { type: Array as PropType<UserTagSelectItem[]>, default: () => [] },
  },
  emit: 'change',
  setup(props: Props, { emit }: SetupContext) {
    return useUserTagSelect(props, emit);
  },
});
