import { useMessages } from '@/base/app';

export type SignUpSendCodeMovePayload = {
  to: number;
};

export function useSignUpSendCode(emit: (name: string, arg?: SignUpSendCodeMovePayload) => void) {
  function submit() {
    emit('submit');
  }
  function back() {
    emit('move', { to: -1 });
  }
  const msgs = useMessages({ prefix: 'account.molecules.signUpSendCode' });
  return {
    description: msgs.of('description'),
    labelRecipient: msgs.of('recipient'),
    labelSubmit: msgs.of('submit'),
    labelBack: msgs.of('back'),
    submit,
    back,
  };
}
