









import { computed, defineComponent } from '@vue/composition-api';

import MyApp from '@/base/app/components/MyApp.vue';

import SignUp from '../components/organisms/SignUp.vue';
import SignUpCode from '../components/organisms/SignUpCode.vue';
import SignUpEmail from '../components/organisms/SignUpEmail.vue';

type Props = {
  tenantCode?: string;
  email?: string;
  code?: string;
};

export default defineComponent({
  name: 'AccountSignUpPage',
  components: { MyApp, SignUp, SignUpEmail, SignUpCode },
  props: {
    tenantCode: { type: String, default: undefined },
    email: { type: String, default: undefined },
    code: { type: String, default: undefined },
  },
  setup(props: Props) {
    const existsEmail = computed(() => props.tenantCode && props.email);
    const existsCode = computed(() => props.tenantCode && props.code);
    return { existsEmail, existsCode };
  },
});
