import { useMessages } from '@/base/app';

export type SignUpIndexEmailMovePayload = {
  to: number;
};

export function useSignUpIndexEmail(
  emit: (name: string, arg: SignUpIndexEmailMovePayload) => void
) {
  function next() {
    emit('move', { to: 1 });
  }
  const msgs = useMessages({ prefix: 'account.molecules.signUpIndexEmail' });
  return {
    description: msgs.of('description'),
    labelTenantCode: msgs.of('tenantCode'),
    labelNext: msgs.of('next'),
    next,
  };
}
