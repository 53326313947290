























































































import { defineComponent } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import LogoButton from '@/base/app/components/atoms/LogoButton.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';
import HintIcon from '@/base/app/components/molecules/HintIcon.vue';
import Loading from '@/base/app/components/organisms/Loading.vue';

import SignInCodeForm from '../molecules/SignInCodeForm.vue';
import SignInEmailForm from '../molecules/SignInEmailForm.vue';
import PasswordDialog from './PasswordDialog.vue';
import { PropsSignIn, useSignIn } from './SignInComposable';

type Props = PropsSignIn;

export default defineComponent({
  name: 'AccountSignIn',
  components: {
    LogoButton,
    SignInEmailForm,
    SignInCodeForm,
    BaseButton,
    ErrorMessages,
    HintIcon,
    PasswordDialog,
    Loading,
  },
  props: {
    signInType: { type: String, default: undefined },
    tenantCode: { type: String, default: undefined },
    userId: { type: String, default: undefined },
    userCode: { type: String, default: undefined },
    email: { type: String, default: undefined },
    token: { type: String, default: undefined },
  },
  setup(props: Props) {
    return useSignIn(props);
  },
});
