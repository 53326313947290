





















import { defineComponent } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import LogoButton from '@/base/app/components/atoms/LogoButton.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';

import EmailForm from '../molecules/EmailForm.vue';
import {
  PropsRequestResetPassword,
  useRequestResetPassword,
} from './RequestResetPasswordComposable';

type Props = PropsRequestResetPassword;

export default defineComponent({
  name: 'AccountRequestResetPassword',
  components: { LogoButton, EmailForm, BaseButton, BaseMarkdown, ErrorMessages },
  props: { email: { type: String, default: undefined } },
  setup(props: Props) {
    return useRequestResetPassword(props);
  },
});
