import { onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';

export type CodeFormValue = string;

export type PropsCodeForm = {
  value: CodeFormValue;
};

export function useCodeForm(
  props: PropsCodeForm,
  emit: (name: string, arg: CodeFormValue) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<CodeFormValue>('');

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    emit('submit', input.value);
  }

  function init() {
    input.value = props.value;
  }
  onMounted(init);
  watch(() => props.value, init);

  const msgs = useMessages({ prefix: 'account.molecules.codeForm' });
  return { observer, input, labelCode: msgs.of('code'), submit };
}
