









































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseRadioGroup from '@/base/app/components/atoms/BaseRadioGroup.vue';
import { GlobalStoreGroup } from '@/base/app/store/types';

import {
  GroupRoleAndGroupsFormValue,
  PropsGroupRoleAndGroupsForm,
  useGroupRoleAndGroupsForm,
} from './GroupRoleAndGroupsFormComposable';

type Props = PropsGroupRoleAndGroupsForm;

export default defineComponent({
  name: 'AccountGroupRoleAndGroupsForm',
  components: { BaseRadioGroup },
  model: { prop: 'value', event: 'submit' },
  props: {
    value: { type: Object as PropType<GroupRoleAndGroupsFormValue>, required: true },
    groups: { type: Array as PropType<GlobalStoreGroup[]>, default: () => [] },
  },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useGroupRoleAndGroupsForm(props, emit);
  },
});
