import { useMessages } from '@/base/app';

import { PasswordFormValue } from './PasswordFormComposable';

export type SignUpPasswordMovePayload = {
  to: number;
};

export type SignUpPasswordValue = PasswordFormValue;

export function useSignUpPassword(
  emit: (name: string, arg: SignUpPasswordValue | SignUpPasswordMovePayload) => void
) {
  function submit(payload: SignUpPasswordValue) {
    emit('change', payload);
    emit('move', { to: 1 });
  }
  function back() {
    emit('move', { to: -1 });
  }
  const msgs = useMessages({ prefix: 'account.molecules.signUpPassword' });
  return {
    description: msgs.of('description'),
    labelNewPassword: msgs.of('newPassword'),
    labelNext: msgs.of('next'),
    labelBack: msgs.of('back'),
    submit,
    back,
  };
}
