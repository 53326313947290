
















































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseRadioGroup from '@/base/app/components/atoms/BaseRadioGroup.vue';
import BaseText from '@/base/app/components/atoms/BaseText.vue';

import { PropsUserRoleFrom, UserRoleFormValue, useUserRoleForm } from './UserRoleFormComposable';

type Props = PropsUserRoleFrom;

export default defineComponent({
  name: 'AccountUserRoleForm',
  components: { BaseText, BaseRadioGroup },
  model: { prop: 'value', event: 'submit' },
  props: {
    value: { type: Object as PropType<UserRoleFormValue>, required: true },
    mode: { type: String as PropType<'email' | 'code'>, required: true },
    requiredName: { type: Boolean, default: false },
    height: { type: String, default: undefined },
  },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useUserRoleForm(props, emit);
  },
});
