









import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';

import { useSignUpIndex } from './SignUpIndexComposable';

export default defineComponent({
  name: 'AccountSignUpIndex',
  components: { BaseMarkdown, BaseButton },
  inheritAttrs: false,
  emits: ['move'],
  setup(_, { emit }: SetupContext) {
    return useSignUpIndex(emit);
  },
});
