















import { defineComponent } from '@vue/composition-api';

import MyApp from '@/base/app/components/MyApp.vue';

import SignIn from '../components/organisms/SignIn.vue';

export default defineComponent({
  name: 'AccountSignInPage',
  components: { MyApp, SignIn },
  props: {
    type: { type: String, default: undefined },
    tenantCode: { type: String, default: undefined },
    id: { type: String, default: undefined },
    code: { type: String, default: undefined },
    email: { type: String, default: undefined },
    token: { type: String, default: undefined },
    resetFrame: { type: String, default: undefined },
  },
});
