import { onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import { GroupRole } from '@/base/domains';
import { assertIsDefined } from '@/utils/Asserts';

export type GroupRoleAndGroupsFormValue = {
  groupRole: GroupRole;
  groupIds: string[];
};

export type PropsGroupRoleAndGroupsForm = {
  value: GroupRoleAndGroupsFormValue;
};

export function useGroupRoleAndGroupsForm(
  props: PropsGroupRoleAndGroupsForm,
  emit: (name: string, arg?: GroupRoleAndGroupsFormValue) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<GroupRoleAndGroupsFormValue>();

  function init() {
    input.value = { ...props.value };
  }
  onMounted(init);
  watch(() => props.value, init);

  async function submit() {
    const valid = observer.value?.validate();
    if (!valid) return;
    assertIsDefined(input.value, 'input');
    emit('submit', { ...input.value });
  }

  const msgs = useMessages({ prefix: 'account.molecules.groupRoleAndGroupsForm' });
  return {
    observer,
    input,
    groupRoles: msgs.listOf('groupRoles'),
    labelGroupRole: msgs.of('groupRole'),
    labelGroups: msgs.of('groups'),
    submit,
  };
}

export type GroupRoleAndGroupsForm = ReturnType<typeof useGroupRoleAndGroupsForm>;
