import { computed, onMounted, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { BaseDialogConfirm } from '@/base/app/components/molecules/BaseDialogConfirmComposable';
import { BaseDialogOk } from '@/base/app/components/molecules/BaseDialogOkComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import {
  UserTable,
  UserTableActionPayload,
  UserTableItem,
} from '@/base/app/components/molecules/UserTableComposable';
import { useGlobalStore } from '@/base/app/store';
import { DialogName, useDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { Optional } from '@/base/types';
import {
  isFailed,
  useChangeUserPasswordByAdmin,
  useChangeUserRoleByAdmin,
  useSetTagsToUsers,
} from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { requiredInject, useRoute } from '@/utils/VueUtils';

import {
  useDisableUsers,
  useEnableUsers,
  useRemoveSignUpReservations,
  useRemoveUsers,
} from '../../../usecases';
import { UsersStoreKey } from '../../stores';
import { InvitedUserDetailsDialog } from '../molecules/InvitedUserDetailsDialogComposable';
import { UserAddInfoDialog } from '../molecules/UserAddInfoDialogComposable';
import { UserTagSelectValue } from '../molecules/UserTagSelectComposable';
import { PasswordDialog, PasswordDialogPayload } from './PasswordDialogComposable';
import { UserCreateDialog, UserCreateDialogDonePayload } from './UserCreateDialogComposable';
import { UserNameDialog } from './UserNameDialogComposable';
import { UserTagsDialog } from './UserTagsDialogComposable';
import { UserTagSetDialog } from './UserTagSetDialogComposable';

const HEADER_KEYS = [
  { value: 'name' },
  { value: 'preview' },
  { value: 'code', mode: 'user_code' },
  { value: 'email', mode: 'email' },
  { value: 'role' },
  { value: 'userStatusLabel' },
];

const MENUS_ENABLED = [
  { value: 'add' },
  { value: 'setTagsToUsers', required: true },
  { value: 'general', required: true },
  { value: 'admin', required: true },
  { value: 'changeName', single: true },
  { value: 'changePassword', single: true },
  { value: 'invitedUserDetails', single: true },
  { value: 'disable', required: true },
  { value: 'removeReservation', required: true },
  { value: 'removeUser', required: true },
];

const MENUS_DISABLED = [
  { value: 'enable', required: true },
  { value: 'removeUser', required: true },
];

export type PasswordParam = { id: string };

function useTable() {
  const table = ref<UserTable>();
  function clear() {
    if (table.value) table.value.clear();
  }
  return { table, clear };
}

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function open(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  return { confirmDialog, open };
}

function useErrorDialog() {
  const errorDialog = ref<BaseDialogOk>();
  function error(errors: ErrorMessage[]) {
    assertIsDefined(errorDialog.value);
    errorDialog.value.error(errors);
  }
  function open(message: string) {
    assertIsDefined(errorDialog.value);
    errorDialog.value.open(message);
  }
  return { errorDialog, error, open };
}

function useUserAddInfoDialog() {
  const userAddInfoDialog = ref<UserAddInfoDialog>();
  function open(target: {
    tenantCode: string;
    userCode: string;
    setInitialPassword: boolean;
    message: string;
  }) {
    assertIsDefined(userAddInfoDialog.value);
    userAddInfoDialog.value.open(target);
  }
  return { userAddInfoDialog, open };
}

function useInvitedUserDetailsDialog() {
  const invitedUserDetailsDialog = ref<InvitedUserDetailsDialog>();
  function open(
    target:
      | { code: string; name: string; groupsBelongsTo: Optional<string[]>; tenantCode: string }
      | { email: string; groupsBelongsTo: Optional<string[]>; name: string }
  ) {
    assertIsDefined(invitedUserDetailsDialog.value);
    invitedUserDetailsDialog.value.open(target);
  }
  return { invitedUserDetailsDialog, open };
}

function usePasswordDialog() {
  const passwordDialog = ref<PasswordDialog>();
  function open(param: PasswordParam) {
    assertIsDefined(passwordDialog.value);
    passwordDialog.value.open(param);
  }
  return { passwordDialog, open };
}

function useUserDialog() {
  const userDialog = ref<UserCreateDialog>();
  function open() {
    if (userDialog.value) userDialog.value.open();
  }
  return { userDialog, open };
}

function useUserNameDialog() {
  const userNameDialog = ref<UserNameDialog>();
  function open(user: UserTableItem) {
    assertIsDefined(userNameDialog.value);
    userNameDialog.value.open({ id: user.id, name: user.name || '' });
  }
  return { userNameDialog, open };
}

function useTagsDialog() {
  const tagsDialog = ref<UserTagsDialog>();
  const { getQuery, moveTo } = useDialogQuery(DialogName.ACCOUNT_USER_TAGS);
  function moveToTags() {
    moveTo();
  }
  function watchTagsDialogQuery() {
    if (!tagsDialog.value) return;
    const q = getQuery();
    if (q) {
      tagsDialog.value.open();
    } else if (tagsDialog.value.opened()) {
      tagsDialog.value.close();
    }
  }
  return { tagsDialog, moveToTags, watchTagsDialogQuery };
}

function useTagSetDialog() {
  const tagSetDialog = ref<UserTagSetDialog>();
  function open(payload: { userIds: string[]; tagIds: string[] }) {
    assertIsDefined(tagSetDialog.value);
    tagSetDialog.value.open(payload);
  }
  return { tagSetDialog, open };
}

export function useAccounts() {
  const msgs = useMessages({ prefix: 'account.organisms.accounts' });
  const route = useRoute();
  const { tenant, isPlayground, findGroup } = useGlobalStore();
  const mode = computed(() => tenant.value?.signUpType);
  const { tagsDialog, moveToTags, watchTagsDialogQuery } = useTagsDialog();
  const { tagSetDialog, open: openTagSet } = useTagSetDialog();
  const { confirmDialog, open: confirm } = useConfirmDialog();

  const { table, clear: clearTableSelection } = useTable();
  const { errorDialog, error, open: info } = useErrorDialog();
  const { userAddInfoDialog, open: openUserAddInfo } = useUserAddInfoDialog();
  const { invitedUserDetailsDialog, open: openInvitedUserDetails } = useInvitedUserDetailsDialog();
  const { passwordDialog, open: openPassword } = usePasswordDialog();
  const { userDialog, open: openUserAdd } = useUserDialog();
  const { userNameDialog, open: openUserName } = useUserNameDialog();
  const { users, userTags, number, loading, fetch } = requiredInject(UsersStoreKey);

  const userTagId = ref<UserTagSelectValue>();
  const disabled = ref(false);

  function init() {
    fetch();
    watchTagsDialogQuery();
  }
  onMounted(init);

  watch(() => route.query, watchTagsDialogQuery);

  function done() {
    clearTableSelection();
    fetch();
  }

  const updatePassword = useChangeUserPasswordByAdmin();
  async function changePassword(payload: PasswordDialogPayload<PasswordParam>) {
    const res = await updatePassword.execute({
      id: payload.params.id,
      password: payload.newPwd,
      forcePasswordChange: payload.force,
    });
    if (isFailed(res)) {
      payload.done(res.errors);
      return;
    }
    waitTransition(() => {
      payload.done();
      info(msgs.of('changedPassword').value);
      done();
    });
  }

  function created(payload: UserCreateDialogDonePayload) {
    if ('email' in payload) {
      info(msgs.of('sent', { email: payload.email }).value);
    } else {
      openUserAddInfo({ ...payload, message: msgs.of('createdAndInform').value });
    }
    done();
  }

  const updateRole = useChangeUserRoleByAdmin();
  async function changeUserRole(ids: string[], role: 'general' | 'admin') {
    const res = await Promise.all(ids.map((id) => updateRole.execute({ id, role })));
    const errors = res.map((r) => (isFailed(r) ? r.errors : [])).reduce((p, c) => p.concat(c), []);
    if (errors.length === 0) return true;
    return errors;
  }

  function openTagSetDialog(tagSetUsers: UserTableItem[]) {
    if (tagSetUsers.length === 1) {
      const [firstUser] = tagSetUsers;
      const tagIds = firstUser.tags?.map((t) => t.id) ?? [];
      openTagSet({ userIds: [firstUser.id], tagIds });
      return;
    }
    const userIds = tagSetUsers.map((u) => u.id);
    openTagSet({ userIds, tagIds: [] });
  }

  const removeTag = useSetTagsToUsers();
  async function removeUserTag(tagId: string, user: UserTableItem) {
    const tagIds = user.tags?.filter((tag) => tag.id !== tagId).map((tag) => tag.id) ?? [];
    const res = await removeTag.execute({ userIds: [user.id], tagIds });
    if (isFailed(res)) {
      error(res.errors);
      return;
    }
    done();
  }

  const removeReservation = useRemoveSignUpReservations();
  async function removeSignUpReservation(user: Array<UserTableItem>) {
    const signUpReservationIds = user.map((item) => item.id);
    const res = await removeReservation.execute({ signUpReservationIds });
    if (isFailed(res)) {
      error(res.errors);
      return;
    }
    done();
  }

  const removeUsers = useRemoveUsers();
  async function removeUser(user: Array<UserTableItem>) {
    const userIds = user.map((item) => item.id);
    const res = await removeUsers.execute({ userIds });
    if (isFailed(res)) {
      error(res.errors);
      return;
    }
    done();
  }

  const enableUsers = useEnableUsers();
  async function enableUser(user: Array<UserTableItem>) {
    const userIds = user.map((item) => item.id);
    const res = await enableUsers.execute({ userIds });
    if (isFailed(res)) {
      error(res.errors);
      return;
    }
    done();
  }

  const disableUsers = useDisableUsers();
  async function disableUser(user: Array<UserTableItem>) {
    const userIds = user.map((item) => item.id);
    const res = await disableUsers.execute({ userIds });
    if (isFailed(res)) {
      error(res.errors);
      return;
    }
    done();
  }

  function validateNotPlayground() {
    if (!isPlayground.value) return true;
    error([msgs.of('errorUnavailableInPlayground').value]);
    return false;
  }

  function findGroupsOfUser(userId: string) {
    const user = users.value.find((u) => u.id === userId);
    if (!user) return undefined;
    if ('groups' in user && user.groups) {
      const groups = user.groups
        .map((g) => findGroup(g.id)?.name)
        .filter((gName) => gName) as string[];
      if (groups?.length === 0) return undefined;
      return groups;
    }
    return undefined;
  }

  async function action({ event, selected }: UserTableActionPayload) {
    switch (event) {
      case 'add': {
        if (!validateNotPlayground()) return;
        if (tenant.value?.userLimit && tenant.value.userLimit <= number.value) {
          error([msgs.of('errorExcessAddUser').value]);
          return;
        }
        openUserAdd();
        break;
      }
      case 'setTagsToUsers': {
        if (selected.length === 0) return;
        if (selected.some((item) => item.userStatus === 'not_signed_up')) {
          error([msgs.of('errorSetTagsNotSignUp').value]);
          return;
        }
        openTagSetDialog(selected);
        break;
      }
      case 'general':
      case 'admin': {
        if (!validateNotPlayground()) return;
        if (selected.length === 0) return;
        const role = event;
        if (selected.some((item) => item.role === 'supervisor')) {
          error([msgs.of('errorChangeRoleSV').value]);
          return;
        }
        if (selected.some((item) => item.userStatus === 'not_signed_up')) {
          error([msgs.of('errorChangeRoleNotSignedUp').value]);
          return;
        }
        if (selected.some((item) => item.role === role)) {
          error([msgs.of('errorChangeRoleSame').value]);
          return;
        }
        loading.value = true;
        const ret = await changeUserRole(
          selected.map((item) => item.id),
          role
        );
        if (ret !== true) {
          loading.value = false;
          error(ret);
          return;
        }
        waitTransition(done);
        break;
      }
      case 'changeName': {
        const [first] = selected;
        assertIsDefined(first, 'first');
        if (first.userStatus === 'not_signed_up') {
          error([msgs.of('errorChangeNameNotSignedUp').value]);
          return;
        }
        openUserName(first);
        break;
      }
      case 'changePassword': {
        if (!validateNotPlayground()) return;
        const [first] = selected;
        assertIsDefined(first, 'first');
        if (first.userStatus === 'not_signed_up') {
          error([msgs.of('errorChangePasswordNotSignedUp').value]);
          return;
        }
        openPassword({ id: first.id });
        break;
      }
      case 'invitedUserDetails': {
        if (!validateNotPlayground()) return;
        const [first] = selected;
        assertIsDefined(first, 'first');
        assertIsDefined(tenant.value, 'tenant');
        if (first.userStatus !== 'not_signed_up') {
          error([msgs.of('errorShowInvitedUserDetails').value]);
          return;
        }
        if (first.email) {
          assertIsDefined(first.email, 'first.email');
          openInvitedUserDetails({
            email: first.email,
            name: first.name ?? '',
            groupsBelongsTo: findGroupsOfUser(first.id),
          });
        } else {
          assertIsDefined(first.code, 'first.code');
          openInvitedUserDetails({
            code: first.code,
            name: first.name ?? '',
            groupsBelongsTo: findGroupsOfUser(first.id),
            tenantCode: tenant.value.code,
          });
        }
        break;
      }
      case 'removeReservation': {
        if (!validateNotPlayground()) return;
        if (selected.length === 0) return;
        if (selected.some((item) => item.userStatus !== 'not_signed_up')) {
          error([msgs.of('errorRemoveReservationUserSignedUp').value]);
          return;
        }
        confirm(msgs.of('confirmRemoveReservationPermanently').value, async () => {
          await removeSignUpReservation(selected);
        });
        break;
      }
      case 'removeUser': {
        if (!validateNotPlayground()) return;
        if (selected.length === 0) return;
        if (selected.some((item) => item.role === 'supervisor')) {
          error([msgs.of('errorRemoveSuperVisor').value]);
          return;
        }
        if (selected.some((item) => item.userStatus === 'not_signed_up')) {
          error([msgs.of('errorRemoveUserNotSignedUp').value]);
          return;
        }
        confirm(msgs.of('confirmRemovePermanently').value, async () => {
          await removeUser(selected);
        });
        break;
      }
      case 'enable': {
        if (!validateNotPlayground()) return;
        if (selected.length === 0) return;
        if (tenant.value?.userLimit && tenant.value.userLimit < number.value + selected.length) {
          error([msgs.of('errorExcessEnableUsers').value]);
          return;
        }
        await enableUser(selected);
        break;
      }
      case 'disable': {
        if (!validateNotPlayground()) return;
        if (selected.length === 0) return;
        if (selected.some((item) => item.userStatus === 'not_signed_up')) {
          error([msgs.of('errorDisabledReservationUserSignedUp').value]);
          return;
        }
        await disableUser(selected);
        break;
      }
      default:
    }
  }

  const headerKeys = computed(() =>
    HEADER_KEYS.filter((item) => !item.mode || item.mode === mode.value).map((item) => item.value)
  );

  const items = computed<UserTableItem[]>(() => {
    const tagId = userTagId.value;
    return users.value
      .filter((u) => (u.status === 'disabled') === disabled.value)
      .filter((u) => {
        if (!tagId) return true;
        if ('tags' in u) return u.tags.some((t) => t.id === tagId);
        return false;
      })
      .map((u) => ({ ...u, userStatus: u.status }));
  });

  const menus = computed(() => {
    if (disabled.value) return MENUS_DISABLED.map((m) => ({ ...m, label: msgs.of(m.value).value }));
    return MENUS_ENABLED.map((m) => ({ ...m, label: msgs.of(m.value).value })).map((m) => ({
      ...m,
      label: msgs.of(m.value).value,
    }));
  });

  const labelNumberOfUsers = computed(() =>
    tenant.value?.userLimit
      ? msgs.of('numberOfUsers', { number: number.value, limit: tenant.value?.userLimit ?? '' })
          .value
      : undefined
  );

  return {
    table,
    confirmDialog,
    errorDialog,
    userAddInfoDialog,
    invitedUserDetailsDialog,
    passwordDialog,
    userDialog,
    userNameDialog,
    tagsDialog,
    tagSetDialog,
    mode,
    users: items,
    userTags,
    userTagId,
    loading,
    disabled,
    headerKeys,
    menus,
    pwdLabel: msgs.of('password'),
    labelTagSetting: msgs.of('tagSetting'),
    labelTagCustom: msgs.of('tagCustom'),
    labelShowDisable: msgs.of('showDisable'),
    labelNumberOfUsers,
    placeholder: msgs.of('tag').value,
    done,
    changePassword,
    created,
    clearTableSelection,
    action,
    moveToTags,
    openTagSetDialog,
    removeUserTag,
  };
}

export type Accounts = ReturnType<typeof useAccounts>;
