































import { defineComponent } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import LogoButton from '@/base/app/components/atoms/LogoButton.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';

import CodePasswordForm from '../molecules/CodePasswordForm.vue';
import { PropsResetPassword, useResetPassword } from './ResetPasswordComposable';

type Props = PropsResetPassword;

export default defineComponent({
  name: 'AccountResetPassword',
  components: { LogoButton, BaseMarkdown, CodePasswordForm, BaseButton, ErrorMessages },
  props: { email: { type: String, required: true } },
  setup(props: Props) {
    return useResetPassword(props);
  },
});
