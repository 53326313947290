

































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import HintIcon from '@/base/app/components/molecules/HintIcon.vue';

import { SignUpUserValue, useSignUpUser } from './SignUpUserComposable';
import UserForm from './UserForm.vue';

export default defineComponent({
  name: 'AccountSignUpUser',
  components: { BaseMarkdown, BaseButton, UserForm, HintIcon },
  inheritAttrs: false,
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: Object as PropType<SignUpUserValue>, required: true },
    mode: { type: String, required: true },
    sv: { type: Boolean, default: false },
    disabledEmail: { type: Boolean, default: false },
    disabledCode: { type: Boolean, default: false },
  },
  emits: ['change', 'move'],
  setup(_, { emit }: SetupContext) {
    return useSignUpUser(emit);
  },
});
