import { computed, onMounted, ref } from '@vue/composition-api';

import { useGetSignUpReservation } from '@/account/usecases/GetSignUpReservation';
import { useMessages } from '@/base/app';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { isSucceeded } from '@/base/usecases';
import { useRouter } from '@/utils/VueUtils';

import { useSignUp } from '../../../usecases';
import { SignUpIndexCodeMovePayload } from '../molecules/SignUpIndexCodeComposable';
import {
  SignUpPasswordMovePayload,
  SignUpPasswordValue,
} from '../molecules/SignUpPasswordComposable';
import { SignUpUserMovePayload, SignUpUserValue } from '../molecules/SignUpUserComposable';

const MIN_HEIGHT = 'min(460px, calc(100vh - 140px))';
const PAGES = ['index', 'user', 'password', 'sign-up'];

type Form = {
  user: SignUpUserValue;
  password: SignUpPasswordValue;
};

export type PropsSignUpCode = {
  tenantCode: string;
  code: string;
};

export function useSignUpCode(props: PropsSignUpCode) {
  const msgs = useMessages({ prefix: 'account.organisms.signUpCode' });
  const router = useRouter();

  const loading = ref(false);
  const notFound = ref(true);
  const page = ref<string>();
  const input = ref<Form>({
    user: { name: '', email: '', code: '' },
    password: { password: '' },
  });
  const errors = ref<ErrorMessage[]>();
  const getSignUpReservation = useGetSignUpReservation();

  async function init() {
    loading.value = true;
    notFound.value = true;
    const res = await getSignUpReservation.execute({
      userCode: props.code,
      tenantCode: props.tenantCode,
    });
    loading.value = false;
    if (isSucceeded(res) && res.signUpReservation) {
      input.value.user.name = res.signUpReservation.name ?? '';
      input.value.user.code = props.code;
      notFound.value = false;
    }
  }
  onMounted(init);

  function movePage(
    payload: SignUpIndexCodeMovePayload | SignUpUserMovePayload | SignUpPasswordMovePayload,
    clearErrors = false
  ) {
    if (!page.value) {
      [page.value] = PAGES;
      return;
    }
    const i = PAGES.indexOf(page.value) + payload.to;
    if (i < 0 || i >= PAGES.length) return;
    page.value = PAGES[i];
    if (clearErrors) errors.value = undefined;
  }

  const signUp = useSignUp();
  async function submit() {
    errors.value = undefined;
    loading.value = true;
    try {
      const res = await signUp.execute({
        userName: input.value.user.name,
        password: input.value.password.password,
        tenantCode: props.tenantCode,
        userCode: props.code,
      });
      if (isSucceeded(res)) {
        router.replace({
          name: 'signIn',
          query: { tenantCode: props.tenantCode, code: props.code },
        });
      } else {
        errors.value = res.errors;
      }
    } catch (e) {
      errors.value = [msgs.of('failed').value];
    }
    loading.value = false;
  }

  function back() {
    movePage({ to: -1 }, true);
  }

  const progress = computed(() => {
    if (!page.value) return 0;
    const i = PAGES.indexOf(page.value);
    return ((i + 1) / PAGES.length) * 100;
  });
  const attention = computed(
    () => msgs.of('attention', { tenantCode: props.tenantCode, code: props.code }).value
  );
  return {
    loading,
    notFound,
    page,
    input,
    errors,
    progress,
    attention,
    description: msgs.of('description'),
    noReservation: msgs.of('noReservation'),
    labelSignUp: msgs.of('signUp'),
    labelBack: msgs.of('back'),
    minHeight: MIN_HEIGHT,
    movePage,
    submit,
    back,
  };
}
