import { ref } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';

import { useMessages } from '@/base/app';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { isFailed } from '@/base/usecases';
import { useRouter } from '@/utils/VueUtils';

import { useConfirmUser, useResendConfirmCode } from '../../../usecases';
import { CodeFormValue } from '../molecules/CodeFormComposable';

export type PropsActivateAccount = {
  id: string;
};

export function useActivateAccount(props: PropsActivateAccount) {
  const msgs = useMessages({ prefix: 'account.organisms.activateAccount' });
  const router = useRouter();

  const code = ref<CodeFormValue>('');
  const loading = ref(false);
  const errors = ref<ErrorMessage[]>();
  const infos = ref<string[]>();

  const confirmUser = useConfirmUser();
  async function submit() {
    errors.value = undefined;
    infos.value = undefined;
    loading.value = true;
    const res = await confirmUser.execute({ userId: props.id, code: code.value });
    if (isFailed(res)) {
      loading.value = false;
      errors.value = res.errors;
      return;
    }
    waitTransition(() => {
      loading.value = false;
      router.replace({ name: 'signIn' });
    });
  }

  const resendConfirmCode = useResendConfirmCode();
  async function resend() {
    errors.value = undefined;
    infos.value = undefined;
    loading.value = true;
    const res = await resendConfirmCode.execute({ userId: props.id });
    if (isFailed(res)) {
      loading.value = false;
      errors.value = res.errors;
      return;
    }
    waitTransition(() => {
      loading.value = false;
      infos.value = [msgs.of('sent').value];
      useTimeoutFn(() => {
        infos.value = undefined;
      }, 3000);
    });
  }

  return {
    code,
    loading,
    errors,
    infos,
    description: msgs.of('description'),
    labelCertificate: msgs.of('certificate'),
    labelResendCode: msgs.of('resendCode'),
    submit,
    resend,
  };
}
