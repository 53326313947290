import { onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';

export type PasswordFormValue = {
  password: string;
  force?: boolean;
};

export type PropsPasswordForm = {
  value: PasswordFormValue;
  label: string;
};

export function usePasswordForm(
  props: PropsPasswordForm,
  emit: (name: string, arg: PasswordFormValue) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<PasswordFormValue>({ password: '' });
  const confirmation = ref();
  const display = ref(false);
  const displayConfirm = ref(false);

  function toggleDisplay() {
    display.value = !display.value;
  }

  function toggleDisplayConfirm() {
    displayConfirm.value = !displayConfirm.value;
  }

  function change(payload: object) {
    Object.assign(input.value, payload);
  }

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    emit('submit', { ...input.value });
  }

  function init() {
    input.value = { ...props.value };
  }
  onMounted(init);
  watch(() => props.value, init);

  function reset() {
    input.value = { password: '' };
    confirmation.value = undefined;
    display.value = false;
    if (observer.value) observer.value.reset();
  }

  const msgs = useMessages({ prefix: 'account.molecules.passwordForm' });
  return {
    observer,
    input,
    confirmation,
    display,
    displayConfirm,
    labelConfirmPassword: msgs.of('confirmPassword'),
    labelForcePasswordChange: msgs.of('forcePasswordChange'),
    pwdTitle: msgs.of('aboutPassword'),
    pwdDescription: msgs.of('description'),
    toggleDisplay,
    toggleDisplayConfirm,
    change,
    submit,
    reset,
  };
}

export type PasswordForm = ReturnType<typeof usePasswordForm>;
