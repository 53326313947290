






















import { defineComponent } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import BaseTextarea from '@/base/app/components/atoms/BaseTextarea.vue';

import { useUserAddInfoDialog } from './UserAddInfoDialogComposable';

export default defineComponent({
  name: 'AccountUserAddInfoDialog',
  components: { BaseButton, BaseMarkdown, BaseTextarea },
  inheritAttrs: false,
  setup() {
    return useUserAddInfoDialog();
  },
});
