import { useMessages } from '@/base/app';

import { TenantFormValue } from './TenantFormComposable';

export type SignUpTenantMovePayload = {
  to: number;
};

export type SignUpTenantValue = TenantFormValue;

export function useSignUpTenant(
  emit: (name: string, arg: SignUpTenantValue | SignUpTenantMovePayload) => void
) {
  function submit(payload: SignUpTenantValue) {
    emit('change', payload);
    emit('move', { to: 1 });
  }
  function back() {
    emit('move', { to: -1 });
  }
  const msgs = useMessages({ prefix: 'account.molecules.signUpTenant' });
  return {
    description: msgs.of('description'),
    hintOfUserCode: msgs.of('hintOfUserCode'),
    labelBack: msgs.of('back'),
    labelNext: msgs.of('next'),
    submit,
    back,
  };
}
