import { computed, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { useRouter } from '@/utils/VueUtils';

type Target = {
  setInitialPassword: boolean;
  message: string;
  userCode: string;
  tenantCode: string;
};

export function useUserAddInfoDialog() {
  const router = useRouter();

  const dialog = ref(false);
  const target = ref<Target>();

  function close() {
    dialog.value = false;
  }

  function open(payload: Target) {
    target.value = payload;
    dialog.value = true;
  }

  const msgs = useMessages({ prefix: 'account.molecules.userAddInfoDialog' });
  const info = computed(() => {
    if (!target.value) return '';
    const { tenantCode, userCode, setInitialPassword } = target.value;
    const r = router.resolve({
      name: setInitialPassword ? 'signIn' : 'signUp',
      query: { tenantCode, code: userCode },
    });
    return msgs.of(setInitialPassword ? 'descriptionSignInCode' : 'descriptionSignUpCode', {
      tenantCode,
      code: userCode,
      path: document.location.origin + r.href,
    }).value;
  });
  return { dialog, info, target, labelClose: msgs.of('close'), close, open };
}

export type UserAddInfoDialog = ReturnType<typeof useUserAddInfoDialog>;
