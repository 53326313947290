







































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseText from '@/base/app/components/atoms/BaseText.vue';

import { PropsUserFrom, UserFormValue, useUserForm } from './UserFormComposable';

type Props = PropsUserFrom;

export default defineComponent({
  name: 'AccountUserForm',
  components: { BaseText },
  model: { prop: 'value', event: 'submit' },
  props: {
    value: { type: Object as PropType<UserFormValue>, required: true },
    mode: { type: String as PropType<'email' | 'code' | 'name-only'>, required: true },
    sv: { type: Boolean, default: false },
    disabledEmail: { type: Boolean, default: false },
    disabledCode: { type: Boolean, default: false },
  },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useUserForm(props, emit);
  },
});
