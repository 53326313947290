






































































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';

import GroupRoleAndGroupsForm from '../molecules/GroupRoleAndGroupsForm.vue';
import PasswordForm from '../molecules/PasswordForm.vue';
import UserCreateTypeForm from '../molecules/UserCreateTypeForm.vue';
import UserRoleForm from '../molecules/UserRoleForm.vue';
import { useUserCreateDialog } from './UserCreateDialogComposable';

export default defineComponent({
  name: 'AccountUserCreateDialog',
  components: {
    BaseButton,
    BaseMarkdown,
    UserCreateTypeForm,
    UserRoleForm,
    PasswordForm,
    GroupRoleAndGroupsForm,
    ErrorMessages,
  },
  inheritAttrs: false,
  props: {
    height: { type: String, default: 'min(80vh, 420px)' },
  },
  emits: ['done'],
  setup(_, { emit }: SetupContext) {
    return useUserCreateDialog(emit);
  },
});
