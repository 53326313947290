import { computed, ref, watch } from '@vue/composition-api';
import moment from 'moment';

import { INVITE_USER_ERROR_GROUP_USER_LIMIT_EXCEEDED } from '@/account/ErrorCodes';
import { useMessages } from '@/base/app';
import {
  ErrorMessage,
  ReplaceError,
} from '@/base/app/components/molecules/ErrorMessagesComposable';
import { useGlobalStore } from '@/base/app/store';
import { ApplicationError } from '@/base/error';
import { isFailed } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';

import {
  CreateUserByAdminRequest,
  InviteUserRequest,
  useCreateUserByAdmin,
  useInviteUser,
} from '../../../usecases';
import {
  GroupRoleAndGroupsForm,
  GroupRoleAndGroupsFormValue,
} from '../molecules/GroupRoleAndGroupsFormComposable';
import { PasswordForm, PasswordFormValue } from '../molecules/PasswordFormComposable';
import {
  UserCreateTypeForm,
  UserCreateTypeFormValue,
} from '../molecules/UserCreateTypeFormComposable';
import { UserRoleForm, UserRoleFormValue } from '../molecules/UserRoleFormComposable';

type UserCreateDialogValue = {
  type: UserCreateTypeFormValue;
  user: UserRoleFormValue;
  pwd: PasswordFormValue;
  groups: GroupRoleAndGroupsFormValue;
};

const PAGES = ['typeForm', 'userRoleForm', 'pwdForm', 'groupsForm', 'add'] as const;
type Page = typeof PAGES[number];

function useErrorReplace() {
  const { findGroup } = useGlobalStore();
  function toMessageParams(aes: ApplicationError[]) {
    const groups = aes
      .map((e) => {
        if (!e.payload) return [];
        const p = e.payload as Parameters<
          typeof INVITE_USER_ERROR_GROUP_USER_LIMIT_EXCEEDED.toApplicationError
        >[0];
        return p?.groupIds.map((id) => findGroup(id)?.name ?? id) ?? [];
      })
      .flat();
    return { groups: groups.join() };
  }
  const errorAddGroupUser: ReplaceError = {
    from: 'AC0003',
    to: {
      prefix: 'account.organisms.userCreateDialog',
      key: 'failedAddGroupUser',
      values: toMessageParams,
    },
  };
  return { replace: [errorAddGroupUser] };
}

export type UserCreateDialogDonePayload = UserCreateTypeFormValue &
  (
    | {
        tenantCode: string;
        userCode: string;
      }
    | {
        email: string;
      }
  );

export function useUserCreateDialog(
  emit: (name: string, arg: UserCreateDialogDonePayload) => void
) {
  const msgs = useMessages({ prefix: 'account.organisms.userCreateDialog' });
  const { tenant, groups: storeGroups } = useGlobalStore();
  const typeForm = ref<UserCreateTypeForm>();
  const userRoleForm = ref<UserRoleForm>();
  const pwdForm = ref<PasswordForm>();
  const groupsForm = ref<GroupRoleAndGroupsForm>();

  const dialog = ref(false);
  const input = ref<UserCreateDialogValue>();
  const errors = ref<ErrorMessage[]>();
  const loading = ref(false);
  const page = ref<Page>('typeForm');
  const pages = computed(() => {
    if (input.value?.type.setInitialPassword) return PAGES;
    return PAGES.filter((p) => p !== 'pwdForm');
  });
  const signUpMode = computed(() => (tenant.value?.signUpType === 'user_code' ? 'code' : 'email'));

  function close() {
    dialog.value = false;
    page.value = 'typeForm';
    errors.value = undefined;
  }

  function open() {
    dialog.value = true;
    input.value = {
      type: { setInitialPassword: false },
      user: { role: 'general', code: '', email: '', name: '' },
      pwd: { password: '', force: true },
      groups: { groupRole: 'trainee', groupIds: [] },
    };
  }

  watch(dialog, (newVal) => {
    if (newVal) return;
    close();
  });

  function back() {
    const i = pages.value.indexOf(page.value);
    if (i === 0) {
      close();
      return;
    }
    page.value = pages.value[i - 1];
    errors.value = undefined;
  }

  const inviteUser = useInviteUser();
  async function invite(v: InviteUserRequest) {
    const ret = await inviteUser.execute(v);
    if (isFailed(ret)) return ret.errors;
    return true;
  }

  const createUser = useCreateUserByAdmin();
  async function create(v: CreateUserByAdminRequest) {
    const ret = await createUser.execute(v);
    if (isFailed(ret)) return ret.errors;
    return true;
  }

  async function add(v: UserCreateDialogValue) {
    const { type, user: u, pwd: p, groups: g } = v;
    const groups =
      g.groupIds.length > 0 ? g.groupIds.map((id) => ({ id, role: g.groupRole })) : undefined;

    errors.value = undefined;
    loading.value = true;

    let ret: ErrorMessage[] | true;
    let payload: UserCreateDialogDonePayload;
    if (signUpMode.value === 'code') {
      assertIsDefined(tenant.value, 'tenant');
      assertIsDefined(u.code, 'userCode');
      payload = {
        ...type,
        tenantCode: tenant.value.code,
        userCode: u.code,
      };
      if (type.setInitialPassword) {
        assertIsDefined(u.name, 'userName');
        assertIsDefined(p.password, 'password');
        ret = await create({
          name: u.name,
          code: u.code,
          password: p.password,
          role: u.role,
          groups,
          forcePasswordChange: p.force ?? true,
        });
      } else {
        ret = await invite({ userCode: u.code, role: u.role, userName: u.name, groups });
      }
    } else {
      assertIsDefined(u.email, 'userEmail');
      payload = { ...type, email: u.email };
      if (type.setInitialPassword) {
        assertIsDefined(u.name, 'userName');
        assertIsDefined(p.password, 'password');
        ret = await create({
          name: u.name,
          email: u.email,
          password: p.password,
          role: u.role,
          groups,
          forcePasswordChange: p.force ?? true,
        });
      } else {
        ret = await invite({ email: u.email, role: u.role, userName: u.name, groups });
      }
    }
    if (ret !== true) {
      errors.value = ret;
      loading.value = false;
      return;
    }
    loading.value = false;
    emit('done', payload);
    close();
  }

  async function next(valid: boolean) {
    if (!valid) {
      switch (page.value) {
        case 'typeForm':
          if (typeForm.value) typeForm.value.submit();
          return;
        case 'userRoleForm':
          if (userRoleForm.value) userRoleForm.value.submit();
          return;
        case 'pwdForm':
          if (pwdForm.value) pwdForm.value.submit();
          return;
        case 'groupsForm':
          if (groupsForm.value) groupsForm.value.submit();
          return;
        case 'add': {
          assertIsDefined(input.value, 'input');
          add(input.value);
          return;
        }
        default:
      }
    }
    const i = pages.value.indexOf(page.value);
    page.value = pages.value[i + 1];
  }

  const labelBack = computed(() => {
    const i = pages.value.indexOf(page.value);
    const key = i === 0 ? 'close' : 'back';
    return msgs.of(key).value;
  });

  const labelNext = computed(() => {
    const key = page.value === 'add' ? 'add' : 'next';
    return msgs.of(key).value;
  });

  const messageConfirm = computed(() => {
    if (signUpMode.value === 'email')
      return msgs.of('confirmInvite', { email: input.value?.user.email ?? '-' }).value;
    return msgs.of('confirmAdd').value;
  });

  const attentions = computed(() => {
    if (!input.value?.type.setInitialPassword) return '';
    const ret = [msgs.of('attentionPassword').value];
    if (input.value.pwd.force) {
      const limit = moment().add(7, 'days').format('YYYY/MM/DD HH:mm');
      ret.push(msgs.of('attentionPasswordLimit', { limit }).value);
    }
    return ret.join('\n');
  });

  const groups = computed(() => [...storeGroups.value].sort((a, b) => (a.name < b.name ? -1 : 1)));

  return {
    typeForm,
    userRoleForm,
    pwdForm,
    groupsForm,
    groups,
    dialog,
    page,
    input,
    errors,
    loading,
    signUpMode,
    labelBack,
    labelNext,
    labelPwd: msgs.of('password'),
    messageConfirm,
    attentions,
    descriptionGroups: msgs.of('descriptionGroups'),
    title: msgs.of('addUser'),
    close,
    open,
    back,
    next,
    ...useErrorReplace(),
  };
}

export type UserCreateDialog = ReturnType<typeof useUserCreateDialog>;
