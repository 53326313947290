import { ref } from '@vue/composition-api';

import { useMessages } from '../..';

export function useHintIcon() {
  const dialog = ref(false);
  function close() {
    dialog.value = false;
  }

  const msgs = useMessages({ prefix: 'base.molecules.hintIcon' });
  return { dialog, labelClose: msgs.of('close'), close };
}
