

































import { defineComponent } from '@vue/composition-api';

import BaseButtonIcon from '../atoms/BaseButtonIcon.vue';
import BaseMarkdown from '../atoms/BaseMarkdown.vue';
import { useHintIcon } from './HintIconComposable';

export default defineComponent({
  name: 'BaseHintIcon',
  components: { BaseButtonIcon, BaseMarkdown },
  props: {
    icon: { type: String, default: 'mdi-help-circle' },
    title: { type: String, default: undefined },
    body: { type: String, required: true },
    buttonSmall: { type: Boolean, default: false },
    buttonXSmall: { type: Boolean, default: false },
    buttonClass: { type: String, default: 'mx-5' },
  },
  setup() {
    return useHintIcon();
  },
});
