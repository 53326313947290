import { useMessages } from '@/base/app';

import { UserFormValue } from './UserFormComposable';

export type SignUpUserMovePayload = {
  to: number;
};

export type SignUpUserValue = UserFormValue;

export function useSignUpUser(
  emit: (name: string, arg: SignUpUserValue | SignUpUserMovePayload) => void
) {
  function submit(payload: UserFormValue) {
    emit('change', payload);
    emit('move', { to: 1 });
  }
  function back() {
    emit('move', { to: -1 });
  }
  const msgs = useMessages({ prefix: 'account.molecules.signUpUser' });
  return {
    description: msgs.of('description'),
    labelBack: msgs.of('back'),
    labelNext: msgs.of('next'),
    hintOfEmailTitle: msgs.of('hintOfEmailTitle'),
    hintOfCodeTitle: msgs.of('hintOfCodeTitle'),
    codeDescription: msgs.of('codeDescription'),
    emailDescription: msgs.of('emailDescription'),
    submit,
    back,
  };
}
