






















import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseText from '@/base/app/components/atoms/BaseText.vue';
import ColorSelect from '@/base/app/components/atoms/ColorSelect.vue';

import { PropsUserTagForm, UserTagFormValue, useUserTagForm } from './UserTagFormComposable';

type Props = PropsUserTagForm;

export default defineComponent({
  name: 'AccountUserTagForm',
  components: { BaseText, ColorSelect },
  model: { prop: 'value', event: 'submit' },
  props: { value: { type: Object as PropType<UserTagFormValue>, required: true } },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useUserTagForm(props, emit);
  },
});
