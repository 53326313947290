






































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseText from '@/base/app/components/atoms/BaseText.vue';
import HintIcon from '@/base/app/components/molecules/HintIcon.vue';

import {
  CodePasswordFormValue,
  PropsCodePasswordForm,
  useCodePasswordForm,
} from './CodePasswordFormComposable';

type Props = PropsCodePasswordForm;

export default defineComponent({
  name: 'AccountCodePasswordForm',
  components: { BaseText, HintIcon },
  model: { prop: 'value', event: 'submit' },
  props: { value: { type: Object as PropType<CodePasswordFormValue>, required: true } },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useCodePasswordForm(props, emit);
  },
});
