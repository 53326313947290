















import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseText from '@/base/app/components/atoms/BaseText.vue';

import { CodeFormValue, PropsCodeForm, useCodeForm } from './CodeFormComposable';

type Props = PropsCodeForm;

export default defineComponent({
  name: 'AccountCodeForm',
  components: { BaseText },
  model: { prop: 'value', event: 'submit' },
  props: { value: { type: String as PropType<CodeFormValue>, required: true } },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useCodeForm(props, emit);
  },
});
