






























import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import UserTagVue from '@/base/app/components/atoms/UserTag.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';
import { UserTagReference } from '@/base/domains';

import { PropsUserTagSetDialog, useUserTagSetDialog } from './UserTagSetDialogComposable';

type Props = PropsUserTagSetDialog;

export default defineComponent({
  name: 'AccountUserTagSetDialog',
  components: { UserTag: UserTagVue, BaseButton, ErrorMessages },
  model: { prop: 'value', event: 'change' },
  ErrorMessages,
  props: {
    tags: { type: Array as PropType<UserTagReference[]>, default: () => [] },
    multiple: { type: Boolean, default: false },
  },
  emits: ['done'],
  setup(props: Props, { emit }: SetupContext) {
    return useUserTagSetDialog(props, emit);
  },
});
