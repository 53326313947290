















import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import UserTag from '@/base/app/components/atoms/UserTag.vue';
import BaseTable from '@/base/app/components/molecules/BaseTable.vue';

import { PropsUserTable, UserTagTableItem, useUserTagTable } from './UserTagTableComposable';

type Props = PropsUserTable;

export default defineComponent({
  name: 'AccountUserTagTable',
  components: { BaseTable, UserTag },
  inheritAttrs: false,
  props: {
    tags: { type: Array as PropType<UserTagTableItem[]>, default: () => [] },
    headerKeys: { type: Array as PropType<string[]>, default: () => ['text', 'preview'] },
  },
  emits: ['action'],
  setup(props: Props, { emit }: SetupContext) {
    return useUserTagTable(props, emit);
  },
});
