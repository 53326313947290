import { computed, nextTick, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { assertIsDefined } from '@/utils/Asserts';

import { PasswordForm, PasswordFormValue } from '../molecules/PasswordFormComposable';

export type PropsPasswordDialog = {
  label: string;
};

export type PasswordDialogPayload<T> = {
  params: T;
  newPwd: string;
  force: boolean;
  done: (failed?: ErrorMessage[]) => void;
};

export function usePasswordDialog<T>(
  props: PropsPasswordDialog,
  emit: (name: string, arg: PasswordDialogPayload<T>) => void
) {
  const dialog = ref(false);
  const loading = ref(false);
  const errors = ref<ErrorMessage[]>();

  const form = ref<PasswordForm>();
  const input = ref<PasswordFormValue>({ password: '', force: false });
  const params = ref<T>();

  function close() {
    dialog.value = false;
    errors.value = undefined;
  }
  watch(dialog, (newVal) => {
    if (!newVal) close();
  });

  function open(payload: T) {
    input.value = { password: '', force: false };
    params.value = payload;
    dialog.value = true;
    nextTick(() => {
      if (form.value) form.value.reset();
    });
  }

  function done(failed?: ErrorMessage[]) {
    loading.value = false;
    errors.value = failed;
    if (failed) return;
    close();
  }

  function submit() {
    assertIsDefined(params.value, 'params');
    errors.value = undefined;
    loading.value = true;
    emit('submit', {
      params: params.value,
      newPwd: input.value.password,
      force: input.value.force ?? false,
      done,
    });
  }

  const msgs = useMessages({ prefix: 'account.organisms.passwordDialog' });
  const description = computed(() => msgs.of('description', { label: props.label }).value);
  return {
    dialog,
    loading,
    errors,
    form,
    input,
    description,
    labelSet: msgs.of('set'),
    labelClose: msgs.of('close'),
    close,
    open,
    submit,
  };
}

export type PasswordDialog = ReturnType<typeof usePasswordDialog>;
