





















































































import { defineComponent } from '@vue/composition-api';

import UserTagSelect from '@/account/app/components/molecules/UserTagSelect.vue';
import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import RefreshButton from '@/base/app/components/atoms/RefreshButton.vue';
import UserTag from '@/base/app/components/atoms/UserTag.vue';
import BaseDialogConfirm from '@/base/app/components/molecules/BaseDialogConfirm.vue';
import BaseDialogOk from '@/base/app/components/molecules/BaseDialogOk.vue';
import UserTable from '@/base/app/components/molecules/UserTable.vue';

import InvitedUserDetailsDialog from '../molecules/InvitedUserDetailsDialog.vue';
import UserAddInfoDialog from '../molecules/UserAddInfoDialog.vue';
import { useAccounts } from './AccountsComposable';
import PasswordDialog from './PasswordDialog.vue';
import UserCreateDialog from './UserCreateDialog.vue';
import UserNameDialog from './UserNameDialog.vue';
import UserTagsDialog from './UserTagsDialog.vue';
import UserTagSetDialog from './UserTagSetDialog.vue';

export default defineComponent({
  name: 'AccountAccounts',
  components: {
    UserTable,
    RefreshButton,
    BaseDialogConfirm,
    BaseDialogOk,
    BaseButtonIcon,
    UserAddInfoDialog,
    InvitedUserDetailsDialog,
    PasswordDialog,
    UserCreateDialog,
    UserTagsDialog,
    UserNameDialog,
    UserTagSelect,
    UserTagSetDialog,
    UserTag,
  },
  setup() {
    return useAccounts();
  },
});
