import { useMessages } from '@/base/app';

export type SignUpTermsOfServiceMovePayload = {
  to: number;
};

export function useSignUpTermsOfService(
  emit: (name: string, arg: SignUpTermsOfServiceMovePayload) => void
) {
  function next() {
    emit('move', { to: 1 });
  }
  function back() {
    emit('move', { to: -1 });
  }
  const msgs = useMessages({ prefix: 'account.molecules.signUpTermsOfService' });
  return { labelAgree: msgs.of('agree'), labelBack: msgs.of('back'), next, back };
}
