import { onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';

export type UserFormValue = {
  name: string;
  email: string;
  code: string;
};

export type PropsUserFrom = {
  value: UserFormValue;
};

export function useUserForm(
  props: PropsUserFrom,
  emit: (name: string, arg: UserFormValue) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<UserFormValue>({ name: '', email: '', code: '' });

  function reset() {
    if (observer.value) observer.value.reset();
  }

  function change(payload: object) {
    Object.assign(input.value, payload);
  }

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    emit('submit', { ...input.value });
  }

  function init() {
    input.value = { ...props.value };
  }
  onMounted(init);
  watch(() => props.value, init);

  const msgs = useMessages({ prefix: 'account.molecules.userForm' });
  return {
    observer,
    input,
    labelName: msgs.of('name'),
    labelMailAddress: msgs.of('mailAddress'),
    labelUserCode: msgs.of('userCode'),
    hint: msgs.of('hintOfUserCode'),
    reset,
    change,
    submit,
  };
}

export type UserForm = ReturnType<typeof useUserForm>;
