import { onMounted, ref } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';

export type SignInEmailFormValue = {
  email: string;
  password: string;
};

export type PropsSignInEmailForm = {
  email?: string;
};

export function useSignInEmailForm(
  props: PropsSignInEmailForm,
  emit: (name: string, arg: SignInEmailFormValue) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<SignInEmailFormValue>({ email: '', password: '' });

  const trimSpaces = (v: string) => v.replaceAll(/^\s+|\s+$/g, '');
  const onEmailChange = (v: string) => {
    input.value = {
      ...input.value,
      email: trimSpaces(v),
    };
  };
  const onPasswordChange = (v: string) => {
    input.value = {
      ...input.value,
      password: trimSpaces(v),
    };
  };

  const display = ref(false);

  function toggleDisplay() {
    display.value = !display.value;
  }

  function init() {
    input.value.email = trimSpaces(props.email ?? '');
  }
  onMounted(init);

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    emit('submit', { ...input.value });
  }
  const msgs = useMessages({ prefix: 'account.molecules.signInEmailForm' });
  return {
    observer,
    input,
    display,
    placeholderMailAddress: msgs.of('placeholderMailAddress'),
    labelMailAddress: msgs.of('labelMailAddress'),
    placeholderPassword: msgs.of('placeholderPassword'),
    labelPassword: msgs.of('labelPassword'),
    submit,
    toggleDisplay,
    onEmailChange,
    onPasswordChange,
  };
}
