











import { computed, defineComponent } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import MyApp from '@/base/app/components/MyApp.vue';
import { useGlobalStore } from '@/base/app/store';
import { provide } from '@/utils/VueUtils';

import Accounts from '../components/organisms/Accounts.vue';
import { UsersStoreKey, useUsersStore } from '../stores';

export default defineComponent({
  name: 'AccountAccountsPage',
  components: { MyApp, Accounts },
  props: {},
  setup() {
    const { tenant } = useGlobalStore();
    const store = useUsersStore();
    provide(UsersStoreKey, store);

    const msgs = useMessages({ prefix: 'account.pages.accountsPage' });
    const labelNumberOfUsers = computed(() =>
      tenant.value?.userLimit
        ? msgs.of('numberOfUsers', {
            number: store.number.value,
            limit: tenant.value?.userLimit ?? '',
          }).value
        : undefined
    );

    return {
      labelAccountManagement: msgs.of('accountManagement'),
      labelNumberOfUsers,
    };
  },
});
