import { nextTick, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { isFailed, useChangeUserNameByAdmin } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';

import { UserForm, UserFormValue } from '../molecules/UserFormComposable';

export function useUserNameDialog(emit: (name: string) => void) {
  const dialog = ref(false);
  const errors = ref<ErrorMessage[]>();
  const loading = ref(false);

  const form = ref<UserForm>();
  const input = ref<UserFormValue>({ name: '', email: '', code: '' });
  const userId = ref<string>();

  function close() {
    dialog.value = false;
    errors.value = undefined;
  }
  watch(dialog, (newVal) => {
    if (!newVal) close();
  });

  function open(target: { id: string; name: string }) {
    input.value = { name: target.name, email: '', code: '' };
    userId.value = target.id;
    dialog.value = true;
    nextTick(() => {
      if (form.value) form.value.reset();
    });
  }

  const changeUserName = useChangeUserNameByAdmin();
  async function submit() {
    assertIsDefined(userId.value, 'userId');
    errors.value = undefined;
    loading.value = true;
    const res = await changeUserName.execute({ userId: userId.value, userName: input.value.name });
    if (isFailed(res)) {
      loading.value = false;
      errors.value = res.errors;
      return;
    }
    waitTransition(() => {
      loading.value = false;
      emit('done');
      close();
    });
  }

  const msgs = useMessages({ prefix: 'account.organisms.userNameDialog' });
  return {
    dialog,
    errors,
    loading,
    form,
    input,
    labelChange: msgs.of('change'),
    labelClose: msgs.of('close'),
    close,
    open,
    submit,
  };
}

export type UserNameDialog = ReturnType<typeof useUserNameDialog>;
