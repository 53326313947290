











import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import { TermsOfService } from '@/base/domains';

import { useSignUpTermsOfService } from './SignUpTermsOfServiceComposable';

export default defineComponent({
  name: 'AccountTermsOfService',
  components: { BaseButton, BaseMarkdown },
  inheritAttrs: false,
  props: { termsOfService: { type: Object as PropType<TermsOfService>, required: true } },
  emits: ['move'],
  setup(_, { emit }: SetupContext) {
    return useSignUpTermsOfService(emit);
  },
});
