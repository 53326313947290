







import { defineComponent } from '@vue/composition-api';

import MyApp from '@/base/app/components/MyApp.vue';

import ResetPassword from '../components/organisms/ResetPassword.vue';

export default defineComponent({
  name: 'AccountResetPasswordPage',
  components: { MyApp, ResetPassword },
  props: { email: { type: String, required: true } },
});
