import { onMounted, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { useGlobalStore } from '@/base/app/store';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { useAddUserDevice } from '@/base/usecases/AddUserDevice';
import {
  createAndroidAccountByCode,
  createAndroidAccountByEmail,
  getAndroidAccount,
  waitSignIn,
} from '@/utils/AndroidUtils';
import { assertIsDefined } from '@/utils/Asserts';

import { SignInCodeFormValue } from '../molecules/SignInCodeFormComposable';
import { SignInEmailFormValue } from '../molecules/SignInEmailFormComposable';
import { PasswordDialog, PasswordDialogPayload } from './PasswordDialogComposable';

type SignInMode = 'code' | 'email' | 'loading';

export type PasswordDialogParams = {
  updatePwd: (newPwd: string) => Promise<void>;
};

export function usePasswordDialog() {
  const passwordDialog = ref<PasswordDialog>();
  function open(params: PasswordDialogParams) {
    assertIsDefined(passwordDialog.value);
    passwordDialog.value.open(params);
  }
  return { passwordDialog, open };
}

export type PropsSignIn = {
  signInType?: string;
  tenantCode?: string;
  userId?: string;
  userCode?: string;
  email?: string;
  token?: string;
};

export function useSignIn(props: PropsSignIn) {
  const msgs = useMessages({ prefix: 'account.organisms.signIn' });

  const { signIn } = useGlobalStore();
  const { passwordDialog, open: openPassword } = usePasswordDialog();

  const mode = ref<SignInMode>();
  const loading = ref(false);
  const errors = ref<ErrorMessage[]>();
  watch(mode, (_, oldVal) => {
    if (oldVal === 'loading') return;
    errors.value = undefined;
  });

  const addUserDevice = useAddUserDevice();
  function addAndroidDeviceToken(params: string[]) {
    if (params.length === 0) return;
    const [deviceToken] = params;
    addUserDevice.execute({ deviceToken, deviceType: 'android' });
  }

  async function signInByEmail(
    email: string,
    password: string,
    token?: string
  ): Promise<string[] | boolean> {
    try {
      const ret = await signIn({ email, password, token });
      if (typeof ret === 'string') {
        createAndroidAccountByEmail(ret, email, password, addAndroidDeviceToken);
        return true;
      }
      openPassword({
        updatePwd: async (newPassword: string) => {
          const id = await ret(newPassword);
          createAndroidAccountByEmail(id, email, newPassword, addAndroidDeviceToken);
        },
      });
      return false;
    } catch (e) {
      return [msgs.of('failedSignInEmail').value];
    }
  }

  async function signInByCode(
    tenantCode: string,
    userCode: string,
    password: string
  ): Promise<string[] | boolean> {
    try {
      const ret = await signIn({ tenantCode, userCode, password });
      if (typeof ret === 'string') {
        createAndroidAccountByCode(ret, tenantCode, userCode, password, addAndroidDeviceToken);
        return true;
      }
      openPassword({
        updatePwd: async (newPassword: string) => {
          const id = await ret(newPassword);
          createAndroidAccountByCode(id, tenantCode, userCode, newPassword, addAndroidDeviceToken);
        },
      });
      return false;
    } catch (e) {
      return [msgs.of('failedSignInCode').value];
    }
  }

  async function signInByAndroidAccount(params: string[]) {
    const [type] = params;
    switch (type) {
      case 'code': {
        const [tenantCode, code, password] = params.slice(1);
        if (!tenantCode || !code || !password) {
          waitSignIn();
          return;
        }
        const ret = await signInByCode(tenantCode, code, password);
        if (Array.isArray(ret)) errors.value = [msgs.of('failedSignInAndroidAccount').value];
        if (ret !== true) waitSignIn();
        break;
      }
      case 'email': {
        const [email, password] = params.slice(1);
        if (!email || !password) {
          waitSignIn();
          return;
        }
        const ret = await signInByEmail(email, password);
        if (Array.isArray(ret)) errors.value = [msgs.of('failedSignInAndroidAccount').value];
        if (ret !== true) waitSignIn();
        break;
      }
      default:
        waitSignIn();
        break;
    }
  }

  function getMode() {
    let m: SignInMode = 'email';
    if (props.email) {
      m = 'email';
    } else if (props.tenantCode) {
      m = 'code';
    } else if (props.signInType === 'email' || props.signInType === 'code') {
      m = props.signInType;
    }
    return m;
  }

  function trySignIn() {
    if (props.userId) {
      mode.value = 'loading';
      loading.value = true;

      const ret = getAndroidAccount(props.userId, async (params: string[]) => {
        await signInByAndroidAccount(params);
        loading.value = false;
        mode.value = getMode();
      });
      if (ret) return;
    }

    loading.value = false;
    mode.value = getMode();
    waitSignIn();
  }

  function init() {
    mode.value = getMode();
    trySignIn();
  }
  onMounted(init);
  watch(() => props.signInType, init);

  async function submitEmail(payload: SignInEmailFormValue) {
    errors.value = undefined;
    loading.value = true;
    const { email, password } = payload;
    const ret = await signInByEmail(email, password, props.token);
    if (Array.isArray(ret)) errors.value = ret;
    loading.value = false;
  }

  async function submitCode(payload: SignInCodeFormValue) {
    errors.value = undefined;
    loading.value = true;
    const { tenantCode, code: userCode, password } = payload;
    const ret = await signInByCode(tenantCode, userCode, password);
    if (Array.isArray(ret)) errors.value = ret;
    loading.value = false;
  }

  async function changePassword(payload: PasswordDialogPayload<PasswordDialogParams>) {
    try {
      await payload.params.updatePwd(payload.newPwd);
      waitTransition(() => {
        payload.done();
      });
    } catch (e) {
      payload.done([msgs.of('failedChangePassword').value]);
    }
  }

  return {
    passwordDialog,
    mode,
    loading,
    errors,
    description: msgs.of('description'),
    descriptionForgotPassword: msgs.of('descriptionForgotPassword'),
    labelEmail: msgs.of('email'),
    labelUserCode: msgs.of('userCode'),
    labelSignIn: msgs.of('signIn'),
    labelForgotPassword: msgs.of('forgotPassword'),
    labelNewPassword: msgs.of('newPassword'),
    submitEmail,
    submitCode,
    changePassword,
  };
}
