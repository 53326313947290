import { computed, onMounted, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { isSucceeded } from '@/base/usecases';
import { useRouter } from '@/utils/VueUtils';

import { useGetSignUpReservation, useSignUp } from '../../../usecases';
import { SignUpIndexEmailMovePayload } from '../molecules/SignUpIndexEmailComposable';
import {
  SignUpPasswordMovePayload,
  SignUpPasswordValue,
} from '../molecules/SignUpPasswordComposable';
import { SignUpSendCodeMovePayload } from '../molecules/SignUpSendCodeComposable';
import { SignUpUserMovePayload, SignUpUserValue } from '../molecules/SignUpUserComposable';

const MIN_HEIGHT = 'min(460px, calc(100vh - 140px))';
const PAGES = ['index', 'user', 'password', 'send-code'];

type Form = {
  user: SignUpUserValue;
  password: SignUpPasswordValue;
};

export type PropsSignUpEmail = {
  tenantCode: string;
  email: string;
};

export function useSignUpEmail(props: PropsSignUpEmail) {
  const msgs = useMessages({ prefix: 'account.organisms.signUpEmail' });
  const router = useRouter();

  const loading = ref(false);
  const notFound = ref(true);
  const page = ref<string>();
  const input = ref<Form>({
    user: { name: '', email: '', code: '' },
    password: { password: '' },
  });
  const errors = ref<ErrorMessage[]>();
  const getSignUpReservation = useGetSignUpReservation();

  async function init() {
    loading.value = true;
    notFound.value = true;
    const res = await getSignUpReservation.execute({ email: props.email });
    loading.value = false;
    if (isSucceeded(res) && res.signUpReservation) {
      input.value.user.name = res.signUpReservation.name ?? '';
      input.value.user.email = props.email;
      notFound.value = false;
    }
  }
  onMounted(init);

  function movePage(
    payload:
      | SignUpIndexEmailMovePayload
      | SignUpUserMovePayload
      | SignUpPasswordMovePayload
      | SignUpSendCodeMovePayload,
    clearErrors = false
  ) {
    if (!page.value) {
      [page.value] = PAGES;
      return;
    }
    const i = PAGES.indexOf(page.value) + payload.to;
    if (i < 0 || i >= PAGES.length) return;
    page.value = PAGES[i];
    if (clearErrors) errors.value = undefined;
  }

  const signUp = useSignUp();
  async function submit() {
    errors.value = undefined;
    loading.value = true;
    try {
      const res = await signUp.execute({
        userName: input.value.user.name,
        password: input.value.password.password,
        tenantCode: props.tenantCode,
        email: props.email,
      });
      if (isSucceeded(res)) {
        router.push({ name: 'activateAccount', query: { id: res.userId } });
      } else {
        errors.value = res.errors;
      }
    } catch (e) {
      errors.value = [msgs.of('failed').value];
    }
    loading.value = false;
  }

  const progress = computed(() => {
    if (!page.value) return 0;
    const i = PAGES.indexOf(page.value);
    return ((i + 1) / PAGES.length) * 100;
  });
  return {
    loading,
    notFound,
    page,
    input,
    errors,
    progress,
    noReservation: msgs.of('noReservation'),
    minHeight: MIN_HEIGHT,
    movePage,
    submit,
  };
}
