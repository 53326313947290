import { computed, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { BaseDialogConfirm } from '@/base/app/components/molecules/BaseDialogConfirmComposable';
import {
  BaseDialogFullScreen,
  BaseDialogFullScreenValue,
} from '@/base/app/components/molecules/BaseDialogFullScreenComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { clearDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { UserTagReference } from '@/base/domains';
import { isFailed, isSucceeded } from '@/base/usecases';
import { useChangeUserTag } from '@/base/usecases/ChangeUserTag';
import { useCreateUserTag } from '@/base/usecases/CreateUserTag';
import { useGetUserTags } from '@/base/usecases/GetUserTags';
import { useRemoveUserTag } from '@/base/usecases/RemoveUserTag';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import { UserTagDialog, UserTagDialogPayload } from '../molecules/UserTagDialogComposable';
import { UserTagTable, UserTagTableActionPayload } from '../molecules/UserTagTableComposable';

const MENUS = [
  { value: 'add' },
  { value: 'edit', single: true },
  { value: 'remove', required: true },
];

function useDialogFullScreen() {
  const dialogFullScreen = ref<BaseDialogFullScreen>();
  const dialog = ref<BaseDialogFullScreenValue>({ display: false });
  function opened() {
    return dialog.value.display;
  }
  function error(errors: ErrorMessage[]) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showErrorDialog(errors);
  }
  function info(msg: string, ok?: () => void) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showDialog(msg, ok);
  }
  return { dialogFullScreen, dialog, opened, error, info };
}

function useTable() {
  const table = ref<UserTagTable>();
  function clear() {
    if (table.value) table.value.clear();
  }
  return { table, clear };
}

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function confirm(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  return { confirmDialog, confirm };
}

export function useUserTagsDialog(emit: (name: string) => void) {
  const msgs = useMessages({ prefix: 'account.organisms.userTagsDialog' });
  const route = useRoute();
  const router = useRouter();
  const { dialogFullScreen, dialog, opened, info, error } = useDialogFullScreen();
  const { table, clear: clearTableSelection } = useTable();
  const { confirmDialog, confirm } = useConfirmDialog();

  const target = ref<{ disabled: boolean }>();
  const tags = ref<UserTagReference[]>([]);
  const loading = ref(false);

  const dialogProps = computed(() => target.value);

  const getTags = useGetUserTags();
  async function fetch() {
    loading.value = true;
    const res = await getTags.execute([]);
    if (isSucceeded(res)) {
      tags.value = res.userTags;
    } else {
      tags.value = [];
      return false;
    }
    loading.value = false;
    return true;
  }

  function refresh() {
    clearTableSelection();
    fetch();
  }

  function done() {
    clearTableSelection();
    fetch();
    emit('done');
  }

  function close() {
    dialog.value = { display: false };
    target.value = undefined;
    tags.value = [];
    clearTableSelection();
    const to = clearDialogQuery(route);
    if (to) router.replace(to);
  }

  async function open(disabled = false) {
    target.value = { disabled };
    dialog.value = { display: true };
    const ret = await fetch();
    if (!ret) info(msgs.of('noData').value, close);
  }

  const createTag = useCreateUserTag();
  const updateTag = useChangeUserTag();
  async function submit(payload: UserTagDialogPayload) {
    loading.value = true;
    if (payload.id) {
      const res = await updateTag.execute({
        id: payload.id,
        text: payload.text,
        color: payload.color,
      });
      if (isFailed(res)) {
        loading.value = false;
        payload.done(res.errors);
        return;
      }
    } else {
      const res = await createTag.execute({
        text: payload.text,
        color: payload.color,
      });
      if (isFailed(res)) {
        loading.value = false;
        payload.done(res.errors);
        return;
      }
    }
    waitTransition(() => {
      payload.done();
      done();
    });
  }

  const removeTag = useRemoveUserTag();
  async function remove(ids: string[]) {
    const ret = await Promise.all(ids.map((id) => removeTag.execute({ id })));
    const errors = ret.map((r) => (isFailed(r) ? r.errors : [])).reduce((p, c) => p.concat(c), []);
    if (errors.length === 0) return true;
    return errors;
  }

  const tagDialog = ref<UserTagDialog>();
  function openTag(tag?: UserTagReference) {
    assertIsDefined(tagDialog.value);
    tagDialog.value.open({ tag });
  }

  function action({ event, selected }: UserTagTableActionPayload) {
    switch (event) {
      case 'add':
        openTag();
        break;
      case 'edit': {
        const [first] = selected;
        assertIsDefined(first, 'first');
        openTag(first);
        break;
      }
      case 'remove': {
        if (selected.length === 0) return;
        confirm(msgs.of('confirmRemove').value, async () => {
          loading.value = true;
          const ret = await remove(selected.map((item) => item.id));
          if (ret !== true) {
            loading.value = false;
            error(ret);
            return;
          }
          waitTransition(done);
        });
        break;
      }
      default:
    }
  }

  const menus = computed(() =>
    MENUS.map((item) => ({ ...item, label: msgs.of(item.value).value }))
  );
  const items = computed(() =>
    tags.value.sort((a, b) => {
      if (a.text === b.text) return a.id < b.id ? -1 : 1;
      return a.text < b.text ? -1 : 1;
    })
  );
  return {
    dialogFullScreen,
    table,
    confirmDialog,
    tagDialog,
    dialog,
    dialogProps,
    loading,
    menus,
    tags: items,
    title: msgs.of('tagManagement'),
    close,
    open,
    opened,
    submit,
    action,
    refresh,
  };
}

export type UserTagsDialog = ReturnType<typeof useUserTagsDialog>;
