














































import { defineComponent } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import BaseTextarea from '@/base/app/components/atoms/BaseTextarea.vue';

import { useInvitedUserDetailsDialog } from './InvitedUserDetailsDialogComposable';

export default defineComponent({
  name: 'AccountInvitedUserDetailsDialog',
  components: { BaseButton, BaseTextarea, BaseMarkdown },
  inheritAttrs: false,
  props: {
    height: { type: String, default: 'min(380px, 80vh)' },
  },
  setup() {
    return useInvitedUserDetailsDialog();
  },
});
